import { notification } from "antd";
import { AxiosError } from "axios";
import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { expandFromRawCode } from "react-diff-view";
import { createContext, useContextSelector } from "use-context-selector";

import TraceableNotificationDescription from "../components/TraceableNotificationDescription";
import { changeSetToFileInfos, FileInfo, getRelevantPath } from "../components/Utils";
import { NavigationBehavior, navigateToSession } from "./Navigation";
import { SessionSortAttribute, SessionSortOrder } from "./SessionBrowsing";
import { useSolverInterfaceContext } from "./SolverInterface";
import { SOLVER_INTERFACE_URL_BASE, solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import {
    SessionChangedEvent,
    SessionStatusEvent,
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    TurnEvent as StreamTurnEvent,
    TurnChangedEvent,
    TurnCreatedEvent,
    TurnDismissedEvent,
    TurnEventType,
} from "./SolverInterfaceEvent";
import {
    AgentThoughtContent,
    BisectContent,
    BlameContent,
    CodeCoverageContent,
    DocumentationContent,
    EditContent,
    ExecutionContent,
    LinterContent,
    ProfileContent,
    RelevantFilesContent,
    RevertContent,
    SolutionReviewContent,
    SolverLogContent,
    TurnChangesContent,
    UnknownTurnEventContent,
    WorkspaceCreationProgressContent,
} from "./TurnEventContent";
import { AuthType, User } from "./User";

import {
    SolverInterfaceEventObserverHandle,
    StreamConnectionStatus,
    useStreamConnection,
} from "../hooks/useStreamConnection";

export enum CreateSessionResultCode {
    NO_ERROR = "no_error",
    REPO_NOT_CONFIGURED = "repo_not_configured",
    BRANCH_NOT_FOUND = "branch_not_found",
    FAILED_TO_CREATE_BRANCH = "failed_to_create_branch",
}

export enum SessionStatus {
    READY = "ready",
    SOLVING = "solving",
    PENDING = "pending",
    ARCHIVED = "archived",
    SUBMITTING_CANCEL = "submitting_cancel",
    SUBMITTING_SOLVE = "submitting_solve",
}

export type SessionStub = {
    session_id: string;
    user_id: string;
    user_name: string;
    user_avatar_url: string;
    auth_type: AuthType;
    status: SessionStatus;
    base_revision: string;
    branch_name: string;
    repo_name: string;
    title: string;
    description: string | undefined;
    linked_issue: string | undefined;
    pending_nl_text: string | undefined;
    is_background: boolean;
    pull_request: string | undefined;
    create_timestamp: number;
    modify_timestamp: number;
    is_read_only: boolean;
};

export type Turn = {
    id: string;
    idx: number;
    error: string | undefined;
    nl_text: string;
    events: TurnEvent[];
};

export interface TurnEvent {
    id: string;
    idx: number;
    event_type: TurnEventType;
    complete: boolean;
    created: number;
    // TODO: Why is this strongly typed?
    content:
        | SolverLogContent
        | WorkspaceCreationProgressContent
        | AgentThoughtContent
        | DocumentationContent
        | UnknownTurnEventContent
        | EditContent
        | TurnChangesContent
        | ProfileContent
        | RelevantFilesContent
        | LinterContent
        | ExecutionContent
        | CodeCoverageContent
        | SolutionReviewContent;
}

export interface SolverLogTurnEvent extends TurnEvent {
    event_type: TurnEventType.SOLVER_LOG;
    content: SolverLogContent;
}

export interface AgentThoughtTurnEvent extends TurnEvent {
    event_type: TurnEventType.AGENT_THOUGHT;
    content: AgentThoughtContent;
}

export interface WorkspaceCreationProgressEvent extends TurnEvent {
    event_type: TurnEventType.WORKSPACE_CREATION_PROGRESS;
    content: WorkspaceCreationProgressContent;
}

export interface TurnChangesEvent extends TurnEvent {
    event_type: TurnEventType.TURN_CHANGES;
    content: TurnChangesContent;
}

export interface DocumentationEvent extends TurnEvent {
    event_type: TurnEventType.DOCUMENTATION;
    content: DocumentationContent;
}

export interface CodeCoverageEvent extends TurnEvent {
    event_type: TurnEventType.CODE_COVERAGE;
    content: CodeCoverageContent;
}

export interface EditEvent extends TurnEvent {
    event_type: TurnEventType.EDIT;
    content: EditContent;
}

export interface RevertEvent extends TurnEvent {
    event_type: TurnEventType.REVERT;
    content: RevertContent;
}

export interface ExecutionEvent extends TurnEvent {
    event_type: TurnEventType.EXECUTION;
    content: ExecutionContent;
}

export interface ProfileEvent extends TurnEvent {
    event_type: TurnEventType.PROFILE;
    content: ProfileContent;
}

export interface LinterErrorsEvent extends TurnEvent {
    event_type: TurnEventType.LINT_ERRORS;
    content: LinterContent;
}

export interface BlameEvent extends TurnEvent {
    event_type: TurnEventType.BLAME;
    content: BlameContent;
}

export interface BisectEvent extends TurnEvent {
    event_type: TurnEventType.BISECT;
    content: BisectContent;
}

export interface RelevantFilesEvent extends TurnEvent {
    event_type: TurnEventType.RELEVANT_FILES;
    content: RelevantFilesContent;
}

export interface SolutionReviewEvent extends TurnEvent {
    event_type: TurnEventType.SOLUTION_REVIEW;
    content: SolutionReviewContent;
}

export enum EventChangeSetState {
    LOADING = "loading",
    FETCHED = "fetched",
    ERROR = "error",
}

export type ChangeSet = {
    changes: ChangedFile[];
    preimages: FileImage[];
    postimages: FileImage[];
    file_infos: FileInfo[];
};

export type EventChangeSet = {
    state: EventChangeSetState;
    event: TurnChangesEvent | EditEvent | RevertEvent;
    changeSet: ChangeSet;
};

export type FileImage = {
    file_path: string;
    hash: string;
    contents: string;
};

export type ChangedFile = {
    patch: string;
    change_ids: string[];
};

export enum LoadingSessionState {
    LOADING = "loading",
    DONE = "done",
    NOT_FOUND = "not_found",
    ERROR = "error",
    CREATING_AND_SOLVING = "creating_and_solving",
}

export type SessionContextType = {
    session: SessionStub | undefined;
    turns: Turn[];
    eventChangeSets: Map<string, EventChangeSet>;
    nlDraftDetail: string;
    sessionStatus: SessionStatus;
    loadingSessionState: LoadingSessionState;
    loadSession: (session_id: string | undefined, navigationBehavior: NavigationBehavior) => Promise<void>;
    syncNLDraft: (detail: string) => void;
    revertHunk: (turn_id: string, change_id: string) => void;
    amendTurn: (turn_id: string, nl_text: string, file_images: FileImage[]) => void;
    undoTurn: (turn_id: string) => void;
    canSolve: (detail: string) => boolean;
    solve: (detail: string) => Promise<void>;
    createAndSolve: (nl_text: string, repo: string, branch: string | undefined) => Promise<void>;
    cancelSolve: () => Promise<void>;
    canModifySession: () => boolean;
    canModifyPendingNL: () => boolean;
    canCancelSolve: () => boolean;
    expandEventFile: (event_id: string, file_path: string, start: number, end: number) => void;
};

// A null object for the SessionContextType that provides a default value for
// |SessionContext|. In pracice, when React renders a SessionProvider and its
// subtree, this value will be overwritten by the |value| of the provider, but
// this is useful for avoiding null checks as a client of exported hooks.
const nullSessionContext: SessionContextType = {
    session: undefined,
    turns: [],
    eventChangeSets: new Map(),
    nlDraftDetail: "",
    sessionStatus: SessionStatus.READY,
    loadingSessionState: LoadingSessionState.DONE,
    loadSession: () => Promise.resolve(),
    syncNLDraft: () => {},
    revertHunk: () => {},
    amendTurn: () => {},
    undoTurn: () => {},
    canSolve: () => false,
    solve: () => Promise.resolve(),
    createAndSolve: () => Promise.resolve(),
    cancelSolve: () => Promise.resolve(),
    canModifySession: () => false,
    canModifyPendingNL: () => false,
    canCancelSolve: () => false,
    expandEventFile: () => {},
};

const SessionContext = createContext<SessionContextType>(nullSessionContext);

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loadingSessionState, setLoadingSessionState] = useState<LoadingSessionState>(LoadingSessionState.DONE);
    const [sessionId, setSessionId] = useState<string | undefined>(undefined);
    const [session, setSession] = useState<SessionStub | undefined>(undefined);
    const [turns, setTurns] = useState<Turn[]>([]);
    const [eventChangeSets, setEventChangeSets] = useState<Map<string, EventChangeSet>>(new Map());
    const [nlDraftDetail, setNLDraftDetail] = useState<string>("");
    const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.READY);
    const [notificationKeys, setNotificationKeys] = useState<string[]>([]);
    const [api, contextHolder] = notification.useNotification();

    const { currentUser, onStreamConnectionErrorResponse, onStreamReconnectionFailed } = useSolverInterfaceContext();

    const { connect, streamConnectionStatus, addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver } =
        useStreamConnection((status: number) => {
            if (status === 404) {
                setLoadingSessionState(LoadingSessionState.NOT_FOUND);
            } else {
                setLoadingSessionState(LoadingSessionState.ERROR);
                onStreamConnectionErrorResponse(status);
            }
        }, onStreamReconnectionFailed);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_STATUS,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionStatusEvent = solverInterfaceEvent as SessionStatusEvent;

                if (sessionStatusEvent.session_id !== session?.session_id) return;

                const newStatus = sessionStatusEvent.status as SessionStatus;

                setSessionStatus(newStatus);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_CHANGED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionChangedEvent = solverInterfaceEvent as SessionChangedEvent;

                if (sessionChangedEvent.session_id !== session?.session_id) return;

                const changedSession = await getSessionBase(session.session_id);

                // Only overwrite the NL draft if we aren't making updates.
                if (session.user_id !== currentUser?.id) {
                    setNLDraftDetail(changedSession.pending_nl_text || "");
                }

                setSession(changedSession);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id, turns]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TURN_EVENT,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const turnEvent = solverInterfaceEvent as StreamTurnEvent;

                if (turnEvent.session_id !== session?.session_id) return;

                addOrUpdateTurnEvent(turnEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    const addOrUpdateTurnEvent = (event: StreamTurnEvent) => {
        if (!session) {
            console.log("addOrUpdateTurnEvent called with no session loaded");
            return;
        }

        const newTurnEvent: TurnEvent = {
            id: event.event_id,
            idx: event.event_idx,
            event_type: event.event_type,
            complete: event.complete,
            created: Date.now() / 1000,
            content: event.content,
        };

        if (
            newTurnEvent.event_type === TurnEventType.TURN_CHANGES ||
            newTurnEvent.event_type === TurnEventType.EDIT ||
            newTurnEvent.event_type === TurnEventType.REVERT
        ) {
            loadChangeSets([newTurnEvent as TurnChangesEvent | EditEvent | RevertEvent]);
        }

        setTurns((prevTurns) => {
            return prevTurns.map((turn) => {
                if (turn.id === event.turn_id) {
                    const prevEvents = turn.events;

                    const eventsLength = prevEvents.length;
                    if (eventsLength === 0) {
                        return { ...turn, events: [newTurnEvent] };
                    } else if (prevEvents[eventsLength - 1].idx < newTurnEvent.idx) {
                        return { ...turn, events: [...prevEvents, newTurnEvent] };
                    }

                    const newEvents = prevEvents.map((turnEvent) => {
                        if (turnEvent.id === newTurnEvent.id) {
                            return newTurnEvent;
                        } else {
                            return turnEvent;
                        }
                    });

                    return { ...turn, events: newEvents };
                } else {
                    return turn;
                }
            });
        });
    };

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TURN_CREATED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const turnCreatedEvent = solverInterfaceEvent as TurnCreatedEvent;

                if (turnCreatedEvent.session_id !== session?.session_id) return;

                const newTurn = await getTurn(session.session_id, turnCreatedEvent.turn_id);

                setTurns((prevTurns) => [...prevTurns, newTurn]);

                setNLDraftDetail("");
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TURN_CHANGED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const turnChangedEvent = solverInterfaceEvent as TurnChangedEvent;

                if (turnChangedEvent.session_id !== session?.session_id) return;

                const changedTurn = await getTurn(turnChangedEvent.session_id, turnChangedEvent.turn_id);

                if (changedTurn.error) {
                    popToastForTurnError(changedTurn.error, turnChangedEvent.session_id);
                }

                setTurns((prevTurns) => {
                    return prevTurns.map((turn) => {
                        if (turn.id === changedTurn.id) {
                            return changedTurn;
                        } else {
                            return turn;
                        }
                    });
                });
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TURN_DISMISSED,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const turnDismissedEvent = solverInterfaceEvent as TurnDismissedEvent;

                if (turnDismissedEvent.session_id !== session?.session_id) return;

                setTurns((prevTurns) => {
                    const oldTurn = prevTurns.find((turn) => turn.id === turnDismissedEvent.turn_id);
                    if (oldTurn) {
                        setNLDraftDetail(oldTurn.nl_text);
                    }
                    const newTurns = prevTurns.filter((turn) => turn.id !== turnDismissedEvent.turn_id);

                    return newTurns;
                });
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    useEffect(() => {
        if (streamConnectionStatus === StreamConnectionStatus.CONNECTED && sessionId) {
            loadSessionDeferred(sessionId);
        }
    }, [streamConnectionStatus, sessionId]);

    const loadSession = async (
        session_id: string | undefined,
        navigationBehavior: NavigationBehavior,
        createAndSolve: boolean = false
    ) => {
        notificationKeys.forEach((key) => notification.destroy(key));

        if (!session_id) {
            clearSession();
            connect(undefined);
            navigateToSession(undefined, navigationBehavior);
            return;
        }

        setLoadingSessionState(createAndSolve ? LoadingSessionState.CREATING_AND_SOLVING : LoadingSessionState.LOADING);
        setSessionId(session_id);

        connect(`${SOLVER_INTERFACE_URL_BASE}/api/connect/sessions/${session_id}`);

        navigateToSession(session_id, navigationBehavior);
    };

    // This needs to happen after the connection is established because we may miss
    // events and be left with an old Session if the connection and Session/Turns
    // requests run at the same time.
    const loadSessionDeferred = async (session_id: string) => {
        try {
            const [newSession, turns] = await Promise.all([getSessionBase(session_id), listTurns(session_id)]);

            setSession({
                ...newSession,
            });
            setTurns(turns);
            setSessionStatus(newSession.status);
            setNotificationKeys([]);
            setLoadingSessionState(LoadingSessionState.DONE);

            rehydrateAsyncEvents(turns.flatMap((turn) => turn.events));

            setNLDraftDetail(newSession.pending_nl_text || "");
        } catch (error) {
            console.log(error);
            setLoadingSessionState(LoadingSessionState.ERROR);
            return;
        }
    };

    const syncNLDraft = (detail: string) => {
        if (!session) {
            console.log("syncNLDraft called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        updateSessionPendingNLText(session.session_id, detail);
    };

    const revertHunk = (turn_id: string, change_id: string) => {
        if (!session) {
            console.log("revertHunk called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        solverInterfaceApiAxios
            .delete<Turn>(`/sessions/${session.session_id}/turns/${turn_id}/change/${change_id}`)
            .then((response) => {
                // If the response is 204, then the turn was deleted as a result of deleting the change.
                const turn = response.status === 204 ? undefined : (response.data as Turn);

                setTurns((prevTurns) => {
                    if (prevTurns.length === 0) {
                        console.error("revertHunk called with no turns");
                        return prevTurns;
                    }

                    if (!turn) {
                        const nlText = prevTurns[prevTurns.length - 1].nl_text;
                        setNLDraftDetail(nlText);
                    }

                    const newTurns = turn ? [...prevTurns.slice(0, -1), turn] : prevTurns.slice(0, -1);

                    return newTurns;
                });
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    const amendTurn = (turn_id: string, nl_text: string, file_images: FileImage[]) => {
        if (!session) {
            console.log("amendTurn called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        solverInterfaceApiAxios
            .patch<Turn>(`/sessions/${session.session_id}/turns/${turn_id}`, { nl_text, file_images })
            .then((response) => {
                // If the response is 204, then the turn was deleted as a result of the amend.
                const turn = response.status === 204 ? undefined : (response.data as Turn);

                setTurns((prevTurns) => {
                    if (prevTurns.length === 0) {
                        console.error("amendTurn called with no turns");
                        return prevTurns;
                    }

                    if (!turn) {
                        const nlText = prevTurns[prevTurns.length - 1].nl_text;
                        setNLDraftDetail(nlText);
                    }

                    const newTurns = turn ? [...prevTurns.slice(0, -1), turn] : prevTurns.slice(0, -1);

                    return newTurns;
                });
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    const undoTurn = (turn_id: string) => {
        if (!session) {
            console.log("undoTurn called with no session loaded");
            return;
        }

        if (!canModifySession()) return;

        solverInterfaceApiAxios
            .delete<void>(`/sessions/${session.session_id}/turns/${turn_id}`)
            .then(() => {
                setTurns((prevTurns) => {
                    if (prevTurns.length === 0) {
                        console.error("undoTurn called with no turns");
                        return prevTurns;
                    }

                    const nlText = prevTurns[prevTurns.length - 1].nl_text;
                    setNLDraftDetail(nlText);

                    return prevTurns.slice(0, -1);
                });
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    const canSolve = (detail: string) => {
        // Need some NL.
        if (!detail) {
            return false;
        } else if (!session) {
            return true;
        }

        return canModifySession();
    };

    const solve = (detail: string) => {
        if (!session) {
            console.log("solve called with no session loaded");
            return Promise.resolve();
        }

        if (!canSolve(detail)) {
            return Promise.resolve();
        }

        setNLDraftDetail(detail);
        setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

        return solverInterfaceApiAxios
            .post<Turn>(`/sessions/${session.session_id}/solve`, { nl_text: detail })
            .then(() => {})
            .catch((error) => {
                if (error.response) {
                    popToastForSolveError(error.response.status, error.response.data.session_id);
                    setSessionStatus(SessionStatus.READY);
                } else {
                    console.log(error);
                }
            });
    };

    const createAndSolve = async (nl_text: string, repo: string, branch: string | undefined) => {
        if (session) {
            console.log("Create and solve called with a session loaded");
            return Promise.resolve();
        }

        if (!canSolve(nl_text)) {
            return Promise.resolve();
        }

        setNLDraftDetail(nl_text);
        setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

        return solverInterfaceApiAxios
            .post(`/sessions/solve`, {
                nl_text: nl_text,
                repo: repo,
                ref: branch,
            })
            .then((response) => loadSession(response.data.session_id, NavigationBehavior.PUSH, true))
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 429) {
                        loadSession(error.response.data.session_id, NavigationBehavior.PUSH);
                    }

                    popToastForSolveError(error.response.status, error.response.data?.session_id);
                } else {
                    console.error(error);
                }
            });
    };

    const cancelSolve = async () => {
        if (!session) {
            console.log("cancelSolve called with no session loaded");
            return Promise.resolve();
        }

        if (!canCancelSolve()) {
            return Promise.resolve();
        }

        // TODO: can we not submit the turn since the solver interface can infer it?
        if (turns.length === 0) {
            console.error("cancelSolve called with no turns");
            return Promise.resolve();
        }
        const lastTurnId = turns[turns.length - 1].id;

        try {
            const response = await solverInterfaceApiAxios.post(
                `/sessions/${session.session_id}/turns/${lastTurnId}/cancel`
            );
            if (response.status === 204) {
                setSessionStatus(SessionStatus.SUBMITTING_CANCEL);
            } else {
                console.log(`Unexpected response code from cancelSolve: ${response.status}`);
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError && error.response) {
                api.error({
                    message: "Error cancelling solve",
                    description: (
                        <TraceableNotificationDescription
                            description="Solve could not be cancelled."
                            session_id={session?.session_id}
                        />
                    ),
                    placement: "bottomRight",
                });
            } else {
                console.log(error);
            }
        }
    };

    const canModifySession = () => {
        if (!session) return false;
        if (currentUser?.id !== session.user_id || session.is_read_only) return false;

        return sessionStatus === SessionStatus.READY;
    };

    const canModifyPendingNL = () => {
        if (!session) return false;
        if (currentUser?.id !== session.user_id || session.is_read_only) return false;

        return sessionStatus === SessionStatus.READY || sessionStatus === SessionStatus.SOLVING;
    };

    const canCancelSolve = () => {
        if (!session) return false;
        if (currentUser?.id !== session.user_id || session.is_read_only) return false;

        return sessionStatus === SessionStatus.SOLVING || sessionStatus === SessionStatus.PENDING;
    };

    const expandEventFile = (event_id: string, file_path: string, start: number, end: number) => {
        const eventChangeSet = eventChangeSets.get(event_id);
        if (!eventChangeSet) {
            console.error(`No change set found for event ${event_id}`);
            return;
        }

        eventChangeSet.changeSet.file_infos = eventChangeSet.changeSet.file_infos.map((fi) => {
            if (getRelevantPath(fi.fileData) === file_path) {
                const source = Array.isArray(fi.source) ? fi.source : (fi.source || "").split("\n");

                fi.fileData.hunks = expandFromRawCode(fi.fileData.hunks, source, start, end);
            }

            return fi;
        });

        setEventChangeSets(
            (prevEventChangeSets) =>
                new Map(
                    prevEventChangeSets.set(event_id, {
                        ...eventChangeSet,
                    })
                )
        );
    };

    const loadChangeSets = async (codeEvents: (TurnChangesEvent | EditEvent | RevertEvent)[]) => {
        setEventChangeSets((prevEventChangeSets) => {
            const entries = Array.from(prevEventChangeSets.entries());

            const newEntries = entries.concat(
                codeEvents.map((event) => [
                    event.id,
                    {
                        state: EventChangeSetState.LOADING,
                        event: event,
                        changeSet: {
                            changes: [],
                            preimages: [],
                            postimages: [],
                            file_infos: [],
                        },
                    },
                ])
            );

            return new Map(newEntries);
        });
    };

    useEffect(() => {
        const fetchChangeSet = async (session_id: string, eventChangeSet: EventChangeSet) => {
            let newState: EventChangeSetState;
            let newChangeSet: ChangeSet;
            try {
                newState = EventChangeSetState.FETCHED;
                newChangeSet = await getChanges(session_id, {
                    include_preimage: eventChangeSet.event.event_type === TurnEventType.TURN_CHANGES,
                    include_postimage: eventChangeSet.event.event_type === TurnEventType.TURN_CHANGES,
                    start: eventChangeSet.event.content.start,
                    end: eventChangeSet.event.content.end,
                });
            } catch (error) {
                newState = EventChangeSetState.ERROR;
                newChangeSet = {
                    changes: [],
                    preimages: [],
                    postimages: [],
                    file_infos: [],
                };
            }

            setEventChangeSets((prevEventChangeSets) => {
                const event_id = eventChangeSet.event.id;
                const changeSet = prevEventChangeSets.get(eventChangeSet.event.id);

                if (!changeSet) {
                    console.warn(`No change set found for event ${event_id}`);
                    return prevEventChangeSets;
                }

                return new Map(
                    prevEventChangeSets.set(event_id, {
                        ...eventChangeSet,
                        state: newState,
                        changeSet: newChangeSet,
                    })
                );
            });
        };

        if (!session) return;

        eventChangeSets.forEach((eventChangeSet) => {
            if (eventChangeSet.state === EventChangeSetState.LOADING) {
                fetchChangeSet(session.session_id, eventChangeSet);
            }
        });
    }, [session?.session_id, eventChangeSets]);

    const rehydrateAsyncEvents = (events: TurnEvent[]) => {
        loadChangeSets(
            events
                .filter(
                    (event) =>
                        event.event_type === TurnEventType.TURN_CHANGES ||
                        event.event_type === TurnEventType.EDIT ||
                        event.event_type === TurnEventType.REVERT
                )
                .map((event) => event as TurnChangesEvent | EditEvent | RevertEvent)
        );
    };

    const clearSession = () => {
        setLoadingSessionState(LoadingSessionState.DONE);
        setSessionId(undefined);
        setSession(undefined);
        setNLDraftDetail("");
        setTurns([]);
        setEventChangeSets(new Map());
        setSessionStatus(SessionStatus.READY);
    };

    const popToastForSolveError = (status: number, session_id: string | undefined = undefined) => {
        if (status === 429) {
            api.error({
                message: "Solve quota exceeded",
                description: (
                    <TraceableNotificationDescription description="Please try again later." session_id={undefined} />
                ),
                placement: "bottomRight",
            });
        } else {
            api.error({
                message: "Error solving",
                description: (
                    <TraceableNotificationDescription
                        description="An error occurred while solving."
                        session_id={session_id}
                    />
                ),
                placement: "bottomRight",
            });
        }
    };

    const popToastForTurnError = (error: string, session_id: string) => {
        const key = Math.random().toString(36).substring(7);
        setNotificationKeys((prevKeys) => [...prevKeys, key]);

        api.error({
            message: "Solving failed",
            description: <TraceableNotificationDescription description={`❌ ${error}`} session_id={session_id} />,
            placement: "bottomRight",
            key: key,
        });
    };

    const canModifySessionDependencies = [session, sessionStatus, currentUser];

    const value = {
        session,
        turns,
        eventChangeSets,
        nlDraftDetail,
        sessionStatus: sessionStatus,
        loadingSessionState: loadingSessionState,
        loadSession: useCallback(
            (session_id: string | undefined, navigationBehavior: NavigationBehavior) =>
                loadSession(session_id, navigationBehavior),
            [session?.session_id]
        ),
        syncNLDraft: useCallback(
            (pending_nl_text: string) => syncNLDraft(pending_nl_text),
            [...canModifySessionDependencies]
        ),
        revertHunk: useCallback(
            (turn_id: string, change_id: string) => revertHunk(turn_id, change_id),
            [turns, ...canModifySessionDependencies]
        ),
        amendTurn: useCallback(
            (turn_id: string, nl_text: string, file_images: FileImage[]) => amendTurn(turn_id, nl_text, file_images),
            [turns, ...canModifySessionDependencies]
        ),
        undoTurn: useCallback((turn_id: string) => undoTurn(turn_id), [turns, ...canModifySessionDependencies]),
        canSolve: useCallback((detail: string) => canSolve(detail), [turns.length, ...canModifySessionDependencies]),
        solve: useCallback((detail: string) => solve(detail), [turns.length, ...canModifySessionDependencies]),
        createAndSolve: useCallback(
            (nl_text: string, repo: string, branch: string | undefined) => createAndSolve(nl_text, repo, branch),
            canModifySessionDependencies
        ),
        cancelSolve: useCallback(() => cancelSolve(), [turns, ...canModifySessionDependencies]),
        canModifySession: useCallback(() => canModifySession(), canModifySessionDependencies),
        canModifyPendingNL: useCallback(() => canModifyPendingNL(), canModifySessionDependencies),
        canCancelSolve: useCallback(() => canCancelSolve(), canModifySessionDependencies),
        expandEventFile: useCallback(
            (event_id: string, file_path: string, start: number, end: number) =>
                expandEventFile(event_id, file_path, start, end),
            [eventChangeSets]
        ),
    };

    return (
        <SessionContext.Provider value={value}>
            {contextHolder}
            {children}
        </SessionContext.Provider>
    );
};

const useSession = () => useContextSelector(SessionContext, (ctx) => ctx.session);
const useTurns = () => useContextSelector(SessionContext, (ctx) => ctx.turns);
const useEventChangeSets = () => useContextSelector(SessionContext, (ctx) => ctx.eventChangeSets);
const useSessionNLDraftDetail = () => useContextSelector(SessionContext, (ctx) => ctx.nlDraftDetail);
const useSessionStatus = () => useContextSelector(SessionContext, (ctx) => ctx.sessionStatus);
const useLoadingSessionState = () => useContextSelector(SessionContext, (ctx) => ctx.loadingSessionState);
const useLoadSession = () => useContextSelector(SessionContext, (ctx) => ctx.loadSession);
const useRevertHunk = () => useContextSelector(SessionContext, (ctx) => ctx.revertHunk);
const useAmendTurn = () => useContextSelector(SessionContext, (ctx) => ctx.amendTurn);
const useUndoTurn = () => useContextSelector(SessionContext, (ctx) => ctx.undoTurn);
const useCanSolve = () => useContextSelector(SessionContext, (ctx) => ctx.canSolve);
const useSolve = () => useContextSelector(SessionContext, (ctx) => ctx.solve);
const useCreateAndSolve = () => useContextSelector(SessionContext, (ctx) => ctx.createAndSolve);
const useCancelSolve = () => useContextSelector(SessionContext, (ctx) => ctx.cancelSolve);
const useCanModifySession = () => useContextSelector(SessionContext, (ctx) => ctx.canModifySession);
const useCanCancelSolve = () => useContextSelector(SessionContext, (ctx) => ctx.canCancelSolve);
const useExpandEventFile = () => useContextSelector(SessionContext, (ctx) => ctx.expandEventFile);

const getSessionBase = async (session_id: string): Promise<SessionStub> => {
    return solverInterfaceApiAxios
        .get<SessionStub>(`/sessions/${session_id}`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const listTurns = async (session_id: string): Promise<Turn[]> => {
    return solverInterfaceApiAxios
        .get<Turn[]>(`/sessions/${session_id}/turns`)
        .then((response) => {
            const turns = response.data;

            return turns;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const getTurn = async (session_id: string, turn_id: string): Promise<Turn> => {
    return solverInterfaceApiAxios
        .get<Turn>(`/sessions/${session_id}/turns/${turn_id}`)
        .then((response) => {
            const turn = response.data;

            return turn;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const getSessions = async (
    org: string,
    repo: string,
    title_filter: string | undefined = undefined,
    user_filters: string[] | undefined = undefined,
    status_filters: SessionStatus[] | undefined = undefined,
    sort_attribute: SessionSortAttribute = SessionSortAttribute.LAST_MODIFIED,
    sort_order: SessionSortOrder = SessionSortOrder.DESCENDING,
    page: number,
    page_size: number
): Promise<SessionStub[]> => {
    const query = qs.stringify(
        {
            ...(title_filter && { title_filter }),
            ...(user_filters && { user_filters }),
            ...(status_filters && { status_filters }),
            ...(sort_attribute && { sort_attribute }),
            ...(sort_order && { sort_order }),
            page,
            page_size,
        },
        { arrayFormat: "repeat" }
    );

    return await solverInterfaceApiAxios
        .get<SessionStub[]>(`${org}/${repo}/sessions?${query}`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const getRepoUsers = async (org: string, repo: string): Promise<User[]> => {
    return await solverInterfaceApiAxios
        .get<User[]>(`${org}/${repo}/users`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const createSession = async (
    repo: string,
    ref: string
): Promise<{
    session: SessionStub | undefined;
    responseCode: CreateSessionResultCode;
}> => {
    return await solverInterfaceApiAxios
        .post<SessionStub>(`/sessions`, { ref, repo })
        .then((response) => {
            return { session: response.data as SessionStub, responseCode: CreateSessionResultCode.NO_ERROR };
        })
        .catch((error) => {
            const response = error.response;
            if (response && response.data.result_code) {
                return { session: undefined, responseCode: response.data.result_code as CreateSessionResultCode };
            } else {
                console.log(error);
                throw error;
            }
        });
};

const deleteSession = async (session_id: string): Promise<boolean> => {
    return solverInterfaceApiAxios
        .delete(`/sessions/${session_id}`)
        .then(() => true)
        .catch(() => false);
};

const cloneSession = async (to_clone: SessionStub): Promise<SessionStub> => {
    return solverInterfaceApiAxios
        .post(`/sessions/${to_clone.session_id}/clone`, { repo_name: to_clone.repo_name })
        .then((response) => response.data as SessionStub)
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const updateSessionTitle = (session_id: string, title: string): Promise<boolean> => {
    return solverInterfaceApiAxios
        .patch(`/sessions/${session_id}`, { title })
        .then(() => true)
        .catch((error) => {
            console.log(error);
            return false;
        });
};

const updateSessionPendingNLText = (session_id: string, pending_nl_text: string): Promise<void> => {
    return solverInterfaceApiAxios
        .patch(`/sessions/${session_id}`, { pending_nl_text })
        .then(() => {})
        .catch((error) => {
            console.log(error);
        });
};

const sendSessionReport = (session_id: string, description: string): Promise<number> => {
    return solverInterfaceApiAxios
        .post<string>(`/sessions/${session_id}/report`, { description })
        .then((response) => response.status)
        .catch((error) => {
            if (error.response) {
                return error.response.status;
            } else {
                console.log(error);
                return null;
            }
        });
};

interface GetChangesOptions {
    include_preimage: boolean;
    include_postimage: boolean;
    start?: string | undefined;
    end?: string | undefined;
}

const getChanges = async (session_id: string, options: GetChangesOptions): Promise<ChangeSet> => {
    const query = qs.stringify({ ...options });

    return solverInterfaceApiAxios
        .get<ChangeSet>(`/sessions/${session_id}/changes?${query}`)
        .then((response) => {
            const changeSet = response.data;

            return { ...changeSet, file_infos: changeSetToFileInfos(changeSet) };
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const createPullRequest = async (session_id: string): Promise<string> => {
    return solverInterfaceApiAxios
        .post(`/sessions/${session_id}/pr`, { description: "" })
        .then((response) => {
            if (response.data.pull_request) {
                return response.data.pull_request;
            } else {
                throw new Error("PR creation failed");
            }
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const getPatchContents = async (session_id: string): Promise<string> => {
    return solverInterfaceApiAxios
        .get<string>(`/sessions/${session_id}/patch`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const sessionIsLoading = (sessionStatus: SessionStatus) => {
    return !(sessionStatus === SessionStatus.READY || sessionStatus === SessionStatus.ARCHIVED);
};

export {
    createPullRequest,
    createSession,
    deleteSession,
    cloneSession,
    getChanges,
    getPatchContents,
    getRepoUsers,
    getSessionBase,
    getSessions,
    sendSessionReport,
    sessionIsLoading,
    SessionProvider,
    updateSessionTitle,
    useAmendTurn,
    useCanCancelSolve,
    useCancelSolve,
    useCanModifySession,
    useCanSolve,
    useCreateAndSolve,
    useEventChangeSets,
    useExpandEventFile,
    useLoadingSessionState,
    useLoadSession,
    useRevertHunk,
    useSession,
    useSessionNLDraftDetail,
    useSessionStatus,
    useSolve,
    useTurns,
    useUndoTurn,
};
