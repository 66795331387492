import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { NavigationProvider } from "./data/Navigation";
import { SessionBrowsingProvider } from "./data/SessionBrowsing";
import { SolverInterfaceContextProvider } from "./data/SolverInterface";
import { SolverInvocationOptionsProvider } from "./data/SolverInvocationOptions";
import { SessionProvider } from "./data/SolverSession";
import { initTelemetry } from "./data/Telemetry";
import "./index.css";
import { APP_THEME } from "./AppTheme";
import SolverApp from "./SolverApp";
import ErrorFallback from "./ErrorFallback";

initTelemetry(window.location.host);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ConfigProvider theme={APP_THEME}>
                <SolverInterfaceContextProvider>
                    <SolverInvocationOptionsProvider>
                        <SessionProvider>
                            <SessionBrowsingProvider>
                                <NavigationProvider>
                                    <SolverApp />
                                </NavigationProvider>
                            </SessionBrowsingProvider>
                        </SessionProvider>
                    </SolverInvocationOptionsProvider>
                </SolverInterfaceContextProvider>
            </ConfigProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

document.addEventListener(
    "keydown",
    (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === "s") e.preventDefault();
    },
    false
);
