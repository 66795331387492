"use client";
import React from "react";
import { Image } from "antd";
import solverLogo from "../images/solver_logo_reverse_transparent.png";

const SolverLogo: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <Image
            className="solver-logo"
            // 6:1 aspect ratio
            width={324}
            height={54}
            preview={false}
            src={solverLogo}
            alt={"Solver logo"}
            onClick={onClick}
        />
    );
};

export default SolverLogo;
