import React from "react";
import { notification, Layout } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import SessionsList from "./SessionsList";
import TraceableNotificationDescription from "./TraceableNotificationDescription";

import { Repo } from "../data/Repos";

import { NavigationBehavior } from "../data/Navigation";
import { useSessionBrowsingContext } from "../data/SessionBrowsing";
import {
    SessionStub,
    CreateSessionResultCode,
    useLoadSession,
    useSession,
    createSession,
    cloneSession,
    deleteSession,
    useSessionStatus,
} from "../data/SolverSession";
import { useSolverInterfaceContext } from "../data/SolverInterface";

const { Sider } = Layout;

const SessionsSider: React.FC = () => {
    const session = useSession();
    const sessionStatus = useSessionStatus();
    const loadSession = useLoadSession();

    const { currentUser, activeRepo, loadingRepos } = useSolverInterfaceContext();
    const { updateTitle } = useSessionBrowsingContext();

    const [api, contextHolder] = notification.useNotification();

    const [collapsed, setCollapsed] = React.useState(false);

    const buildSessionsList = () => {
        if (loadingRepos) {
            return undefined;
        } else if (!activeRepo) {
            return undefined;
        } else {
            return (
                <SessionsList
                    currentUser={currentUser}
                    repo={activeRepo}
                    activeSession={session}
                    activeSessionStatus={sessionStatus}
                    onNewSession={async (branch: string) => {
                        try {
                            const result = await createSession(activeRepo.fullName, branch);

                            if (result?.session) {
                                await loadSession(result.session.session_id, NavigationBehavior.PUSH);
                            } else {
                                api.error({
                                    message: "Failed to create session",
                                    description: createFailedMessage(result.responseCode, branch, activeRepo),
                                    placement: "bottomRight",
                                });
                            }
                        } catch (error: unknown) {
                            api.error({
                                message: "Failed to create session",
                                description: error instanceof Error ? error.toString() : "Unknown error",
                                placement: "bottomRight",
                            });
                        }
                    }}
                    onSwitchSession={(s: SessionStub) => {
                        if (s && s.session_id !== session?.session_id) {
                            return loadSession(s.session_id, NavigationBehavior.PUSH);
                        } else {
                            return Promise.resolve();
                        }
                    }}
                    onUpdateTitle={async (session: SessionStub, newName: string): Promise<boolean> => {
                        if (currentUser?.id !== session.user_id) return false;

                        const updatedTitle = updateTitle(session.session_id, newName);

                        if (!updatedTitle) {
                            api.error({
                                message: "Updating failed",
                                description: (
                                    <TraceableNotificationDescription
                                        description="Failed to update Session."
                                        session_id={session.session_id}
                                    />
                                ),
                                placement: "bottomRight",
                            });
                        }

                        return updatedTitle;
                    }}
                    onDeleteSession={async (sessionToDelete: SessionStub) => {
                        if (currentUser?.id !== sessionToDelete.user_id) return;

                        const deleted = await deleteSession(sessionToDelete.session_id);

                        if (deleted && sessionToDelete.session_id === session?.session_id) {
                            await loadSession(undefined, NavigationBehavior.PUSH);
                        }

                        if (!deleted) {
                            api.error({
                                message: "Failed to delete Session",
                                placement: "bottomRight",
                            });
                            return;
                        }
                    }}
                    onCloneSession={async (sessionToClone: SessionStub) => {
                        if (currentUser?.id === sessionToClone.user_id) return;

                        const cloned = await cloneSession(sessionToClone);

                        if (cloned) {
                            await loadSession(cloned.session_id, NavigationBehavior.PUSH);
                        } else {
                            api.error({
                                message: "Failed to clone Session",
                                placement: "bottomRight",
                            });
                        }
                    }}
                    notification={api}
                />
            );
        }
    };

    const triggerWidth = collapsed ? 20 : 15;

    return (
        <>
            <Sider
                collapsible
                collapsedWidth={0}
                collapsed={collapsed}
                trigger={
                    collapsed ? (
                        <RightOutlined className="sider-trigger-icon" />
                    ) : (
                        <LeftOutlined className="sider-trigger-icon" />
                    )
                }
                onCollapse={(value) => setCollapsed(value)}
                width={310}
                zeroWidthTriggerStyle={{
                    top: "400px",
                    width: triggerWidth,
                    right: "0px",
                    borderRadius: "0",
                    fontSize: "1.25em",
                    background: "transparent",
                    backgroundColor: "#404040",
                }}
                className={`sider ${collapsed ? "sider-collapsed" : "sider-expanded"}`}
            >
                {buildSessionsList()}
            </Sider>
            {contextHolder}
        </>
    );
};

const createFailedMessage = (code: CreateSessionResultCode | undefined, branch: string, repo: Repo) => {
    switch (code) {
        case CreateSessionResultCode.REPO_NOT_CONFIGURED:
            return `${repo.fullName} is not configured.`;
        case CreateSessionResultCode.BRANCH_NOT_FOUND:
            return `Branch "${branch}" not found in ${repo.fullName}.`;
        case CreateSessionResultCode.FAILED_TO_CREATE_BRANCH:
            return `Failed to create branch "${branch}" for ${repo.fullName}.`;
        default:
            return "Failed to create session.";
    }
};

export default SessionsSider;
