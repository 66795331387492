import React, { useState } from "react";

import { Button, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import "./TraceableNotificationDescription.css";

enum CopyState {
    Copied,
    NotCopied,
    NotAllowed,
}

const TraceableNotificationDescription: React.FC<{
    description: string;
    session_id?: string;
}> = ({ description, session_id }) => {
    const [copiedSessionId, setCopiedSessionId] = useState<CopyState>(CopyState.NotCopied);

    const copySessionId = async () => {
        if (!session_id) return;

        try {
            await navigator.clipboard.writeText(session_id);

            setCopiedSessionId(CopyState.Copied);

            setTimeout(() => {
                setCopiedSessionId(CopyState.NotCopied);
            }, 3000);
        } catch (e) {
            setCopiedSessionId(CopyState.NotAllowed);
        }
    };

    const isTooltipOpen = () => {
        switch (copiedSessionId) {
            case CopyState.Copied:
                return true;
            case CopyState.NotCopied:
                return false;
            case CopyState.NotAllowed:
                return true;
        }
    };

    const tooltipTitle = () => {
        switch (copiedSessionId) {
            case CopyState.Copied:
                return "Copied Session ID";
            case CopyState.NotAllowed:
                return "Copying to the clipboard is not allowed";
        }
    };

    const buildCopyButton = () => {
        if (!session_id) return undefined;

        return (
            <Tooltip open={isTooltipOpen()} title={tooltipTitle()} placement="right">
                <Button size="small" onClick={copySessionId}>
                    <CopyOutlined className="notification-description-copy-icon" />
                    Copy Session ID
                </Button>
            </Tooltip>
        );
    };

    return (
        <div>
            <div>{description}</div>
            {buildCopyButton()}
        </div>
    );
};

export default TraceableNotificationDescription;
