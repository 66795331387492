import React, { createContext, useEffect, useState } from "react";

import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { useSolverInterfaceContext } from "./SolverInterface";

export type InvocationOptionType = "int" | "float" | "bool" | "string";

export interface SolverInvocationOption {
    name: string;
    display_name: string;
    description: string;
    type: InvocationOptionType;
}
export type SolverInvocationOptions = Array<SolverInvocationOption>;

export interface IntegerInvocationOption extends SolverInvocationOption {
    type: "int";
    min: number;
    max: number;
    default: number;
    value: number;
}

export interface FloatInvocationOption extends SolverInvocationOption {
    type: "float";
    min: number;
    max: number;
    default: number;
    value: number;
}

export interface BooleanInvocationOption extends SolverInvocationOption {
    type: "bool";
    default: boolean;
    value: boolean;
}

export interface StringInvocationOption extends SolverInvocationOption {
    type: "string";
    valid_options: Array<string>;
    display: { [key: string]: string };
    default: string;
    value: string;
}

export type InvocationOptionValue = number | boolean | string;

export type SolverInvocationOptionsContextType = {
    invocationOptions: SolverInvocationOptions | undefined;
    updateInvocationOption: (name: string, value: InvocationOptionValue) => void;
    saveInvocationOptions: () => void;
    resetInvocationOptions: () => void;
};

const SolverInvocationOptionsContext = createContext<SolverInvocationOptionsContextType | undefined>(undefined);

const SolverInvocationOptionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { appIsReady } = useSolverInterfaceContext();
    const [invocationOptions, setInvocationOptions] = useState<SolverInvocationOptions | undefined>(undefined);

    useEffect(() => {
        if (!appIsReady) {
            setInvocationOptions(undefined);
        } else {
            getInvocationOptions().then(setInvocationOptions);
        }
    }, [appIsReady]);

    const updateInvocationOption = (name: string, value: InvocationOptionValue) => {
        const newInvocationOptions = invocationOptions?.map((o) => {
            if (o.name === name) {
                return { ...o, value: value } as SolverInvocationOption;
            }
            return o;
        });
        setInvocationOptions(newInvocationOptions);
    };

    const saveInvocationOptions = () => {
        if (!invocationOptions) {
            return;
        }
        const payload: { [key: string]: InvocationOptionValue } = {};
        invocationOptions.forEach((option) => {
            const typedOption = option as
                | IntegerInvocationOption
                | FloatInvocationOption
                | BooleanInvocationOption
                | StringInvocationOption;

            payload[option.name] = typedOption.value;
        });

        solverInterfaceApiAxios
            .post("/invocation_options", payload)
            .then(() => {})
            .catch((error) => {
                console.log(error);
            });
    };

    const resetInvocationOptions = () => {
        if (!invocationOptions) {
            return;
        }

        solverInterfaceApiAxios
            .delete("/invocation_options")
            .then(() => getInvocationOptions())
            .then((options) => setInvocationOptions(options))
            .catch((error) => {
                console.log(error);
            });
    };

    const value = {
        invocationOptions,
        updateInvocationOption,
        saveInvocationOptions,
        resetInvocationOptions,
    };

    return <SolverInvocationOptionsContext.Provider value={value}>{children}</SolverInvocationOptionsContext.Provider>;
};

const useSolverInvocationOptionsContext = () => {
    const ctx = React.useContext(SolverInvocationOptionsContext);
    if (!ctx) {
        throw new Error("useSolverInvocationOptionsContext must be used within a SolverInvocationOptionsProvider");
    }
    return ctx;
};

const getInvocationOptions = async (): Promise<SolverInvocationOptions> => {
    return await solverInterfaceApiAxios
        .get("/invocation_options")
        .then((resp) => {
            return resp.data.map((option: SolverInvocationOption) => {
                switch (option.type) {
                    case "int":
                        return option as IntegerInvocationOption;
                    case "float":
                        return option as FloatInvocationOption;
                    case "bool":
                        return option as BooleanInvocationOption;
                    case "string":
                        return option as StringInvocationOption;
                    default:
                        console.error("Unknown invocation option type: " + option.type);
                }
            });
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
};

export { SolverInvocationOptionsProvider, useSolverInvocationOptionsContext };
