import React from "react";
import { Button, Image, Tooltip } from "antd";
import { SolverInterfaceStatus, useSolverInterfaceContext } from "../data/SolverInterface";
import { GithubOutlined, GitlabOutlined, LoadingOutlined } from "@ant-design/icons";
import { AuthType } from "../data/User";

import laredoLabsLogoIconTransparent from "../images/ll_logo_icon_transparent.png";

const Startup: React.FC = () => {
    const { solverInterfaceStatus, login, signUpToken } = useSolverInterfaceContext();

    const startupElement = () => {
        switch (solverInterfaceStatus) {
            case SolverInterfaceStatus.ERROR:
                return <span className="startup-text">Failed to connect to Solver</span>;
            case SolverInterfaceStatus.FAILED_TO_LOAD_REPOS:
                return <span className="startup-text">Failed to load repos</span>;
            case SolverInterfaceStatus.SIGN_UP_TOKEN_INVALID:
                return (
                    <span className="startup-text">
                        Sign up link invalid or expired. Please contact us for a new sign up link.
                    </span>
                );
            case SolverInterfaceStatus.LOGGED_OUT:
                return (
                    <div className="startup-login-container">
                        {signUpToken && (
                            <div className="startup-sign-up-banner">
                                <Image src={laredoLabsLogoIconTransparent} preview={false} width={50} height={50} />
                                <div className="startup-sign-up-banner-text">
                                    Welcome to the Solver! Click login below to complete sign up and get started with
                                    the future of software engineering.
                                </div>
                            </div>
                        )}
                        <Button className="startup-button" onClick={() => login(AuthType.GitHub, signUpToken)}>
                            <GithubOutlined />
                            Login with GitHub
                        </Button>
                        <Tooltip placement="bottom" title="Coming Soon">
                            <Button
                                disabled={true}
                                className="startup-button"
                                onClick={() => login(AuthType.GitLab, signUpToken)}
                            >
                                <GitlabOutlined />
                                Login with GitLab
                            </Button>
                        </Tooltip>
                    </div>
                );
            case SolverInterfaceStatus.LOADING:
                return <LoadingOutlined style={{ fontSize: "40px" }} />;
            case SolverInterfaceStatus.USER_NOT_ALLOWLISTED:
                return (
                    <div className="startup-text">
                        Thank you for your interest in using the Solver! We're working on rolling out to more
                        organizations and individuals. Please visit{" "}
                        <a className="startup-link" href="https://laredolabs.com/early-access" target="_blank">
                            https://laredolabs.com/early-access
                        </a>{" "}
                        to request access.
                    </div>
                );
            default:
                return null;
        }
    };

    return <div className="startup">{startupElement()}</div>;
};

export default Startup;
