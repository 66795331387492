import React from "react";
import { PlanContent } from "../../data/TurnEventContent";
import MermaidDiagram from "../MermaidDiagram";

interface PlanProps {
    planContent: PlanContent;
}

const Plan: React.FC<PlanProps> = ({ planContent }) => {
    return <MermaidDiagram diagram={planContent.mermaid} />;
};

export default Plan;
