"use client";

import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Collapse, CollapseProps, Tooltip } from "antd";
import "prism-themes/themes/prism-vsc-dark-plus.css";
import React, { useMemo } from "react";
import { Diff, GutterOptions } from "react-diff-view";
import { FileImage } from "../data/SolverSession";
import "./DiffCard.css";
import FileEditor from "./FileEditor";
import {
    DiffHeader,
    FileInfo,
    fileWasRenamed,
    getRelevantPath,
    HeaderLines,
    highlightSyntax,
    HunkHighlight,
    renderHunk,
} from "./Utils";

export const MutableDiffCard: React.FC<{
    fileInfo: FileInfo;
    postImage: FileImage | undefined;
    hunkHighlights: HunkHighlight[];
    updateFileFn: (FileImage: FileImage) => void;
    updateFileDisabled: boolean;
    expandCodeFn: (start: number, end: number) => void;
}> = ({ fileInfo, postImage, hunkHighlights, updateFileFn, updateFileDisabled, expandCodeFn }) => {
    const fileData = fileInfo.fileData;
    const filename = getRelevantPath(fileData);
    const hunks = fileData.hunks || [];

    const tokens = useMemo(() => highlightSyntax(hunks, fileInfo.source, filename), [hunks, filename, fileInfo.source]);

    const firstHunkStartLine = fileData.hunks.length > 0 ? hunks[0].newStart : 1;

    const [editLineNumber, setEditLineNumber] = React.useState<number | undefined>(undefined);
    const [editFile, setEditFile] = React.useState<boolean>(false);
    const [editorContents, setEditorContents] = React.useState<string>(postImage?.contents || "");

    const buildCollapseContent = () => {
        if (editFile && postImage) {
            const preimageContents =
                typeof fileInfo.source === "string"
                    ? fileInfo.source
                    : fileInfo.source
                    ? fileInfo.source.join("\n")
                    : "";

            return (
                <FileEditor
                    preimageContents={preimageContents}
                    postimageContents={postImage.contents}
                    preimageFilename={filename}
                    postimageFilename={postImage.file_path}
                    lineNumber={editLineNumber || firstHunkStartLine}
                    onChange={(value) => setEditorContents(value || "")}
                    onSave={onFinishEdit}
                />
            );
        } else {
            return (
                <Diff
                    key={`mutable-diff-card-${filename}`}
                    gutterType="anchor"
                    viewType={"unified"}
                    diffType={fileData.type}
                    renderGutter={renderGutter}
                    hunks={hunks}
                    tokens={tokens}
                >
                    {(hunks) =>
                        hunks.flatMap((hunk, idx, hunks) =>
                            renderHunk(hunk, idx, hunks, hunkHighlights, fileInfo.fileLines, expandCodeFn)
                        )
                    }
                </Diff>
            );
        }
    };

    const buildCard = (): CollapseProps["items"] => {
        return [
            {
                key: 0,
                label: <DiffHeader fileData={fileData} />,
                children: buildCollapseContent(),
                className: "diff-card",
                style: { padding: "0px" },
                extra: buildHeaderExtra(),
            },
        ];
    };

    const buildHeaderExtra = () => {
        return (
            <div className="header-extra-stats-actions">
                <HeaderLines fileInfo={fileInfo} />
                {buildEditButton()}
            </div>
        );
    };

    const buildEditButton = () => {
        if (!fileIsEditable()) {
            return (
                <Tooltip title="Deleted or renamed files cannot be edited" arrow={false}>
                    <Button
                        className="antd-small-button-text"
                        size="small"
                        disabled={true}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <span className="small-button-text">Edit</span>
                    </Button>
                </Tooltip>
            );
        }

        if (editFile) {
            return (
                <div className="header-button-row">
                    <Button
                        size="small"
                        onClick={(e) => {
                            onFinishEdit();
                            e.stopPropagation();
                        }}
                    >
                        <span className="small-button-text">Save</span>
                    </Button>
                    <Button
                        size="small"
                        onClick={(e) => {
                            setEditFile(false);
                            setEditLineNumber(undefined);
                            setEditorContents(postImage?.contents || "");
                            e.stopPropagation();
                        }}
                    >
                        <span className="small-button-text">Cancel</span>
                    </Button>
                </div>
            );
        } else {
            return (
                <Button
                    size="small"
                    disabled={updateFileDisabled}
                    onClick={(e) => {
                        onEdit(firstHunkStartLine);
                        e.stopPropagation();
                    }}
                >
                    <span className="small-button-text">Edit</span>
                </Button>
            );
        }
    };

    const fileIsEditable = () => {
        if (fileWasRenamed(fileInfo.fileData.type, fileInfo.fileData.similarity)) return false;
        return fileInfo.fileData.type !== "delete";
    };

    const onEdit = (lineNumber: number) => {
        setEditFile(true);
        setEditLineNumber(lineNumber);
    };

    const onFinishEdit = () => {
        if (!fileIsEditable()) return;
        if (!editFile) return;
        if (!postImage) return;
        if (!editorContents) return;

        updateFileFn({ ...postImage, contents: editorContents });

        setEditFile(false);
        setEditLineNumber(undefined);
    };

    const renderGutter = ({ change, side, renderDefault, wrapInAnchor }: GutterOptions) => {
        if (side === "old" || !fileIsEditable()) return wrapInAnchor(renderDefault());

        const lineNumber = change.type === "normal" ? change.newLineNumber : change.lineNumber;

        return (
            <Tooltip title={`Edit from line ${lineNumber}`} arrow={false}>
                <a className="diff-gutter-edit-link" onClick={() => onEdit(lineNumber)}>
                    {renderDefault()}
                </a>
            </Tooltip>
        );
    };

    return (
        <>
            <Collapse
                className="diff-card-container"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={buildCard()}
                defaultActiveKey={fileInfo.fileData.type !== "delete" ? [0] : undefined}
            />
        </>
    );
};
