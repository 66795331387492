"use client";
import React, { ReactNode, useEffect } from "react";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import {
    BooleanInvocationOption,
    StringInvocationOption,
    FloatInvocationOption,
    IntegerInvocationOption,
    SolverInvocationOption,
    InvocationOptionValue,
    useSolverInvocationOptionsContext,
} from "../data/SolverInvocationOptions";
import { Button, Divider, Popover, Select, Slider, Switch, Tooltip, Typography } from "antd";
import { GithubOutlined, GitlabOutlined } from "@ant-design/icons";
import "../index.css";
import { AuthType } from "../data/User";

interface SettingsPopoverProps extends React.HTMLAttributes<HTMLDivElement> {
    popoverOpen: boolean;
    onOpenChange: (open: boolean) => void;
    children: ReactNode;
}

const SettingsPopover: React.FC<SettingsPopoverProps> = ({ popoverOpen, onOpenChange, children }) => {
    const { appIsReady, loggedIn, logout, currentUser } = useSolverInterfaceContext();
    const invocationOptionsCtx = useSolverInvocationOptionsContext();

    const updateInvocationOption = (name: string, value: InvocationOptionValue) =>
        invocationOptionsCtx.updateInvocationOption(name, value);

    useEffect(() => {
        if (!popoverOpen && appIsReady) {
            invocationOptionsCtx.saveInvocationOptions();
        }
    }, [popoverOpen]);

    const buildPopoverContent = () => {
        return (
            <div className="settings-container">
                <div className="settings-title-container">
                    <Typography.Title level={5} className="settings-title">
                        Settings
                    </Typography.Title>
                    <div className="settings-logout-container">
                        {buildCurrentUser()}
                        {logoutButton()}
                    </div>
                </div>
                <Divider type="horizontal" />
                {buildForm()}
            </div>
        );
    };

    const onLogout = () => {
        logout();

        onOpenChange(false);
    };

    const logoutButton = () => {
        return <Button onClick={onLogout}>Logout</Button>;
    };

    const authTypeIcon = (authType: AuthType) => {
        switch (authType) {
            case AuthType.GitHub:
                return <GithubOutlined />;
            case AuthType.GitLab:
                return <GitlabOutlined />;
            default:
                return null;
        }
    };

    const buildCurrentUser = () => {
        if (!currentUser) return null;

        return (
            <span className="settings-current-user">
                <Typography.Text strong>{currentUser.name}</Typography.Text>
                {authTypeIcon(currentUser.auth_type)}
            </span>
        );
    };

    const buildForm = () => {
        if (!invocationOptionsCtx.invocationOptions || invocationOptionsCtx.invocationOptions.length === 0) {
            return <div className="settings-none">No settings available</div>;
        }

        return (
            <>
                {invocationOptionsCtx.invocationOptions.map((option, idx) => buildSettingsItem(option, idx))}
                <div className="settings-buttons">
                    <Button type="primary" onClick={() => onOpenChange(false)}>
                        Done
                    </Button>
                    <Button onClick={() => invocationOptionsCtx.resetInvocationOptions()}>Reset to defaults</Button>
                </div>
            </>
        );
    };

    const buildSettingsItem = (option: SolverInvocationOption, idx: number) => {
        const typedOption = option as
            | IntegerInvocationOption
            | FloatInvocationOption
            | BooleanInvocationOption
            | StringInvocationOption;

        return (
            <Tooltip title={typedOption.description} placement="left" key={idx}>
                <div className="settings-item">
                    <div className="settings-item-label">
                        <label>{typedOption.display_name}</label>
                        <label className="settings-item-default">
                            default: <span>{defaultText(typedOption)}</span>
                        </label>
                    </div>
                    <div className="settings-item-input">{buildFormItemInput(option)}</div>
                </div>
            </Tooltip>
        );
    };

    const buildFormItemInput = (option: SolverInvocationOption) => {
        switch (option.type) {
            case "int": {
                const integerOption = option as IntegerInvocationOption;

                // TODO: Would be nice to see more marks. Could use the greatest
                // non-self divisor of the range to get more marks.
                return (
                    <Slider
                        min={integerOption.min}
                        max={integerOption.max}
                        step={1}
                        onChange={(value) => updateInvocationOption(option.name, value)}
                        marks={{
                            [integerOption.min]: integerOption.min,
                            [integerOption.max]: integerOption.max,
                            [integerOption.default]: integerOption.default,
                        }}
                        value={integerOption.value ? integerOption.value : integerOption.default}
                    />
                );
            }
            case "float": {
                const floatOption = option as FloatInvocationOption;

                return (
                    <Slider
                        min={floatOption.min}
                        max={floatOption.max}
                        step={0.1}
                        onChange={(value) => updateInvocationOption(option.name, value)}
                        marks={{
                            [floatOption.min]: floatOption.min,
                            [floatOption.max]: floatOption.max,
                            [floatOption.default]: floatOption.default,
                        }}
                        value={floatOption.value ? floatOption.value : floatOption.default}
                    />
                );
            }
            case "bool": {
                const booleanOption = option as BooleanInvocationOption;
                return (
                    <Switch
                        checked={booleanOption.value !== null ? booleanOption.value : booleanOption.default}
                        onChange={(checked) => updateInvocationOption(option.name, checked)}
                    />
                );
            }
            case "string": {
                const stringOption = option as StringInvocationOption;

                const selectOptions = stringOption.valid_options.map((option) => {
                    const displayText: string | undefined = stringOption.display[option];

                    return { label: displayText ? displayText : option, value: option };
                });

                return (
                    <Select
                        defaultValue={stringOption.default}
                        value={stringOption.value ? stringOption.value : stringOption.default}
                        options={selectOptions}
                        onChange={(value) => updateInvocationOption(option.name, value)}
                    />
                );
            }
            default:
                console.error(`Unknown option type: ${option.type}`);
                return null;
        }
    };

    const defaultText = (
        typedOption: IntegerInvocationOption | FloatInvocationOption | BooleanInvocationOption | StringInvocationOption
    ) => {
        if (typedOption.type === "bool") {
            return `${typedOption.default ? "on" : "off"}`;
        } else if (typedOption.type === "string") {
            const defaultDisplayText: string | undefined = typedOption.display[typedOption.default];

            if (defaultDisplayText) {
                return defaultDisplayText;
            }
        }

        return `${typedOption.default}`;
    };

    return (
        <Popover
            content={buildPopoverContent()}
            open={popoverOpen}
            trigger="click"
            arrow={false}
            onOpenChange={(open) => {
                onOpenChange(open);
            }}
            placement="bottomRight"
        >
            {children}
        </Popover>
    );
};

export default SettingsPopover;
