"use client";
import classNames from "classnames";
import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";

import { MessageType } from "./MessageType";

import { TurnEventType } from "../data/SolverInterfaceEvent";
import "./Message.css";

const MAX_COLLAPSED_HEIGHT = 150;
const MAX_COLLAPSED_BUFFER = 30;

export interface MessageProps {
    renderContent: () => ReactNode;
    messageType: MessageType;
    collapsible?: boolean;
    collapsedThresholdPx?: number;
    eventType?: TurnEventType;
    key?: string;
    defaultExpanded?: boolean;
}

const Message: React.FC<MessageProps> = ({
    renderContent,
    messageType,
    collapsible = true,
    collapsedThresholdPx = MAX_COLLAPSED_HEIGHT,
    defaultExpanded = true,
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const [isCollapsible, setIsCollapsible] = useState(false);
    const collapsibleContentRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (collapsible && collapsibleContentRef.current) {
            setIsCollapsible(collapsibleContentRef.current.scrollHeight > collapsedThresholdPx + MAX_COLLAPSED_BUFFER);
        }
    });

    const messageClass = classNames({
        message: true,
        "user-message": messageType === MessageType.USER,
        "agent-message": messageType === MessageType.AGENT,
        "message-collapsed": isCollapsible && !isExpanded,
        "message-expanded": isCollapsible && isExpanded,
    });

    const maxHeight = () => {
        if (!collapsible) return "none";

        return isExpanded ? "none" : collapsedThresholdPx;
    };

    const hover = () => {
        return isCollapsible ? "pointer" : "default";
    };

    return (
        <div
            className={messageClass}
            ref={collapsibleContentRef}
            style={{ maxHeight: maxHeight(), cursor: hover() }}
            onClick={(e) => {
                if (!isCollapsible || document.getSelection()?.toString() !== "") {
                    return;
                }
                setIsExpanded((alreadyExpanded) => {
                    e.stopPropagation();
                    return !alreadyExpanded;
                });
            }}
        >
            {renderContent()}
        </div>
    );
};

export default Message;
