import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";

export type Repo = {
    fullName: string;
    org: string;
    name: string;
    icon: string;
    defaultBranch: string | undefined;
    branches: string[];
};

export type GetReposResponse = {
    repos: Repo[];
    error: number | undefined;
};

export const getRepos = async (): Promise<GetReposResponse> => {
    return await solverInterfaceApiAxios
        .get<Repo[]>(`/repos`)
        .then((response) => {
            return { repos: response.data, error: undefined };
        })
        .catch((error) => {
            if (error.response) {
                return { repos: [], error: error.response.status };
            } else {
                throw error;
            }
        });
};
