export type User = {
    id: string;
    auth_type: AuthType;
    name: string;
    avatar_url: string;
    allowlisted: boolean;
};

export enum AuthType {
    GitHub = "github",
    GitLab = "gitlab",
}

export const pullRequestUrl = (authType: AuthType, repo: string, prNumber: number) => {
    switch (authType) {
        case AuthType.GitHub:
            return `https://github.com/${repo}/pull/${prNumber}`;
        case AuthType.GitLab:
            return `https://gitlab.com/${repo}/-/merge_requests/${prNumber}`;
    }
};

export const revisionUrl = (authType: AuthType, repo: string, revision: string) => {
    switch (authType) {
        case AuthType.GitHub:
            return `https://github.com/${repo}/tree/${revision}`;
        case AuthType.GitLab:
            return `https://gitlab.com/${repo}/-/tree/${revision}`;
    }
};

const PROD_GITHUB_APP_URL = "https://github.com/apps/laredo-labs-sync/installations/new";
const NON_PROD_GITHUB_APP_URL = "https://github.com/apps/laredo-labs-sync-internal/installations/new";

export const vcsProviderRepoConfigUrl = (authType: AuthType) => {
    switch (authType) {
        case AuthType.GitHub:
            const domainParts = window.location.host.split(".");
            if (domainParts.length < 2) return NON_PROD_GITHUB_APP_URL;

            const domainEnv = domainParts[1];
            if (domainEnv === "solver") return PROD_GITHUB_APP_URL;

            return NON_PROD_GITHUB_APP_URL;
        case AuthType.GitLab:
            return "";
    }
};
