"use client";
import React from "react";
import { Image, Space, Typography } from "antd";
import "../index.css";
import { Repo } from "../data/Repos";

const RepoCard: React.FC<{ repo: Repo }> = ({ repo }) => {
    return (
        <Space align="center">
            <Image width={36} height={36} preview={false} src={repo.icon} />
            <div className="repo-title-container">
                <Typography.Title level={5} className="repo-title">
                    {repo.org} / {repo.name}
                </Typography.Title>
            </div>
        </Space>
    );
};

export default RepoCard;
