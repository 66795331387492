import mermaid from "mermaid";
import React, { useEffect } from "react";

interface MermaidDiagramProps {
    diagram: string;
}

const MermaidDiagram: React.FC<MermaidDiagramProps> = ({ diagram }) => {
    const [renderError, setRenderError] = React.useState(false);

    const diagramRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        mermaid.initialize({
            startOnLoad: true,
            themeVariables: {
                fontSize: "20px",
                fontFamily: "Arial, sans-serif",
                lineHeight: 1.5,
                lineColor: "#111111",
                edgeLabelBackground: "transparent",
            },
            flowchart: {
                useMaxWidth: true,
                htmlLabels: true,
                curve: "basis",
                nodeSpacing: 70,
                rankSpacing: 70,
            },
            theme: "default",
        });
    }, []);

    useEffect(() => {
        const renderMermaid = async () => {
            if (diagramRef.current) {
                try {
                    const { svg } = await mermaid.render("mermaidDiagram", diagram);
                    diagramRef.current.innerHTML = svg;
                    setRenderError(false);
                } catch (error) {
                    console.error("Mermaid rendering failed:", error);
                    setRenderError(true);
                }
            }
        };

        renderMermaid();
    }, [diagram]);

    return (
        <div style={{ width: "100%", overflowX: "auto" }}>
            {renderError ? (
                <pre>{diagram}</pre>
            ) : (
                <div ref={diagramRef} style={{ maxWidth: "100%", overflowX: "auto" }} />
            )}
        </div>
    );
};

export default MermaidDiagram;
