"use client";

import React, { useEffect, useRef } from "react";
import { DiffEditor } from "@monaco-editor/react";
import { editor } from "monaco-editor/esm/vs/editor/editor.api";

// TODO: this is a temporary solution until our PR that fixes language
// detection in @monaco-editor/react is merged and released:
// https://github.com/suren-atoyan/monaco-react/pull/582
const extensionToLanguage: Map<string, string> = new Map([
    ["ts", "typescript"],
    ["tsx", "typescript"],
    ["js", "javascript"],
    ["es6", "javascript"],
    ["jsx", "javascript"],
    ["py", "python"],
    ["cpp", "cpp"],
    ["cc", "cpp"],
    ["cxx", "cpp"],
    ["hpp", "cpp"],
    ["hh", "cpp"],
    ["hxx", "cpp"],
    ["c", "c"],
    ["h", "c"],
    ["rs", "rust"],
    ["rb", "ruby"],
    ["go", "go"],
    ["java", "java"],
    ["php", "php"],
    ["html", "html"],
    ["css", "css"],
    ["sass", "sass"],
    ["scss", "scss"],
    ["less", "less"],
    ["json", "json"],
    ["md", "markdown"],
    ["txt", "text"],
    ["yaml", "yaml"],
    ["yml", "yaml"],
    ["xml", "xml"],
]);

export const FileEditor: React.FC<{
    preimageContents: string;
    postimageContents: string;
    preimageFilename: string;
    postimageFilename: string;
    lineNumber: number;
    onChange: (value: string | undefined) => void;
    onSave: (value: string | undefined) => void;
}> = ({
    preimageContents,
    postimageContents,
    preimageFilename,
    postimageFilename,
    lineNumber,
    onChange /*onSave*/,
}) => {
    const editorRef = useRef<editor.IStandaloneDiffEditor | null>(null);

    const onEditorMount = (diffEditor: editor.IStandaloneDiffEditor) => {
        //const originalEditor = diffEditor.getOriginalEditor();
        const modifiedEditor = diffEditor.getModifiedEditor();

        // TODO: Using a hotkey to save the editor contents is not working on the first invocation.
        // Maybe use a ref here to keep track of the editor contents instead of state from the parent?
        // originalEditor.onKeyDown((e: IKeyboardEvent) => saveEditor(e));
        // modifiedEditor.onKeyDown((e: IKeyboardEvent) => saveEditor(e));

        modifiedEditor.onDidChangeModelContent(() => onChange(modifiedEditor.getValue()));

        editorRef.current = diffEditor;
    };

    useEffect(() => {
        scheduleScroll();
    }, []);

    const scheduleScroll = () => {
        setTimeout(() => {
            if (editorRef.current) {
                editorRef.current.revealLineInCenter(lineNumber, editor.ScrollType.Smooth);
            }
        }, 250);
    };

    // const isSaveHotkey = (e: IKeyboardEvent) => {
    //     if ((e.ctrlKey || e.metaKey) && (e.keyCode === KeyCode.Enter || e.keyCode === KeyCode.KeyS)) return true;
    //     if (e.keyCode === KeyCode.Escape) return true;

    //     return false;
    // }

    // const saveEditor = async (e: IKeyboardEvent) => {
    //     if (isSaveHotkey(e)) {
    //         e.preventDefault();
    //         e.stopPropagation();

    //         onSave(postimageContents);
    //     }
    // }

    const getExtension = (filename: string) => {
        const extension = filename.split(".").pop();
        if (!extension) return "txt";
        return extension;
    };

    const preimageExtension = getExtension(preimageFilename);
    const postimageExtension = getExtension(postimageFilename);

    return (
        <DiffEditor
            original={preimageContents}
            modified={postimageContents}
            originalLanguage={extensionToLanguage.get(preimageExtension)}
            modifiedLanguage={extensionToLanguage.get(postimageExtension)}
            options={{ smoothScrolling: true }}
            height="70vh"
            theme="vs-dark"
            onMount={onEditorMount}
        />
    );
};

export default FileEditor;
