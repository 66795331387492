"use client";
import React from "react";

import { Button, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { SolutionReviewContent } from "../../data/TurnEventContent";
import SolverMarkdown from "../SolverMarkdown";

import "./SolutionReview.css";

interface SolutionReviewProps {
    solutionReview: SolutionReviewContent;
    onSuggestionClick: (suggestion: string) => void;
}

const SolutionReview: React.FC<SolutionReviewProps> = ({ solutionReview, onSuggestionClick }) => {
    if (solutionReview.error) {
        return null;
    }

    const codeReviewIssues = Array.isArray(solutionReview.review_issues)
        ? solutionReview.review_issues.slice(0, 3)
        : [];
    const guidelinesSuggestions = Array.isArray(solutionReview.guidelines_suggestions)
        ? solutionReview.guidelines_suggestions.slice(0, 3)
        : [];
    const taskSuggestions = Array.isArray(solutionReview.task_suggestions)
        ? solutionReview.task_suggestions.slice(0, 3)
        : [];

    return (
        <div>
            {solutionReview.init ? (
                <SolverMarkdown text={solutionReview.init_message} />
            ) : (
                <>
                    <Tooltip title={solutionReview.task_summary}>
                        <InfoCircleOutlined className="task-summary-icon" />
                    </Tooltip>

                    <SolverMarkdown text="### Summary:" />
                    <SolverMarkdown text={solutionReview.impl_summary} />

                    <div className="suggestions-container">
                        {taskSuggestions.map((suggestion, index) => (
                            <Button
                                key={index}
                                className="solver-finished-cta suggestion-button"
                                onClick={() => onSuggestionClick(suggestion)}
                            >
                                <SolverMarkdown text={suggestion} />
                            </Button>
                        ))}
                    </div>

                    <br />
                    <SolverMarkdown text="### Code Review:" />
                    <SolverMarkdown text={solutionReview.review_summary} />

                    <div className="suggestions-container">
                        {codeReviewIssues.map((suggestion, index) => (
                            <Button
                                key={index}
                                className="solver-finished-cta suggestion-button"
                                onClick={() => onSuggestionClick(suggestion)}
                            >
                                <SolverMarkdown text={suggestion} />
                            </Button>
                        ))}
                    </div>

                    {solutionReview.guidelines_summary && (
                        <>
                            <br />
                            <SolverMarkdown text="### Guidelines Review:" />
                            <SolverMarkdown text={solutionReview.guidelines_summary} />

                            <div className="suggestions-container">
                                {guidelinesSuggestions.map((suggestion, index) => (
                                    <Button
                                        key={index}
                                        className="solver-finished-cta suggestion-button"
                                        onClick={() => onSuggestionClick(suggestion)}
                                    >
                                        <SolverMarkdown text={suggestion} />
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default SolutionReview;
