import React, { createContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";
import {
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    SessionChangedEvent,
    SessionCreatedEvent,
    SessionDeletedEvent,
    SessionStatusEvent,
} from "./SolverInterfaceEvent";
import {
    SessionStatus,
    SessionStub,
    getRepoUsers,
    getSessionBase,
    getSessions,
    updateSessionTitle,
} from "./SolverSession";
import { User } from "./User";

import { SolverInterfaceEventObserverHandle } from "../hooks/useStreamConnection";

const SESSIONS_PAGE_SIZE = 20;

export enum SessionStatusFilter {
    ALL = "All",
    READY = "Ready",
    SOLVING = "Solving",
    PENDING = "Pending",
    ARCHIVED = "Archived",
}

export enum SessionSortAttribute {
    CREATED = "created",
    LAST_MODIFIED = "last_modified",
}

export enum SessionSortOrder {
    ASCENDING = "ascending",
    DESCENDING = "descending",
}

const sessionStatusFilterToSessionStatuses = (status: SessionStatusFilter): SessionStatus[] => {
    switch (status) {
        case SessionStatusFilter.READY:
            return [SessionStatus.READY];
        case SessionStatusFilter.PENDING:
            return [SessionStatus.PENDING];
        case SessionStatusFilter.SOLVING:
            return [SessionStatus.SOLVING];
        case SessionStatusFilter.ARCHIVED:
            return [SessionStatus.ARCHIVED];
        case SessionStatusFilter.ALL:
        default:
            return Object.values(SessionStatus).filter(
                (status) =>
                    status !== SessionStatus.ARCHIVED &&
                    status !== SessionStatus.SUBMITTING_CANCEL &&
                    status !== SessionStatus.SUBMITTING_SOLVE
            );
    }
};

type SessionBrowsingContextType = {
    sessions: SessionStub[];
    loadingSessions: boolean;
    page: number;
    haveMoreSessions: boolean;
    authors: User[];
    loadingAuthors: boolean;
    titleFilter: string;
    setTitleFilter: (filter: string) => void;
    statusFilter: SessionStatusFilter;
    setStatusFilter: (statusFilter: SessionStatusFilter) => void;
    authorFilters: string[];
    setAuthorFilters: (userIds: string[]) => void;
    addAuthorFilter: (user_id: string) => void;
    removeAuthorFilter: (user_id: string) => void;
    sortAttribute: SessionSortAttribute;
    setSortAttribute: (sortAttribute: SessionSortAttribute) => void;
    sortOrder: SessionSortOrder;
    setSortOrder: (sortOrder: SessionSortOrder) => void;
    loadSessions: (org: string, repo: string) => void;
    loadMoreSessions: (org: string, repo: string) => void;
    updateTitle: (session_id: string, newName: string) => Promise<boolean>;
    resetBrowsingFilters: () => void;
};

const nullSessionBrowsingContext: SessionBrowsingContextType = {
    sessions: [],
    loadingSessions: false,
    page: 0,
    haveMoreSessions: true,
    authors: [],
    loadingAuthors: false,
    titleFilter: "",
    setTitleFilter: () => {},
    statusFilter: SessionStatusFilter.ALL,
    setStatusFilter: () => {},
    authorFilters: [],
    setAuthorFilters: () => {},
    addAuthorFilter: () => {},
    removeAuthorFilter: () => {},
    sortAttribute: SessionSortAttribute.LAST_MODIFIED,
    setSortAttribute: () => {},
    sortOrder: SessionSortOrder.DESCENDING,
    setSortOrder: () => {},
    loadSessions: () => {},
    loadMoreSessions: () => {},
    updateTitle: async () => false,
    resetBrowsingFilters: () => {},
};

const SessionBrowsingContext = createContext<SessionBrowsingContextType>(nullSessionBrowsingContext);

const SessionBrowsingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sessions, setSessions] = useState<SessionStub[]>([]);
    const [page, setPage] = useState<number>(0);
    const [haveMoreSessions, setHaveMoreSessions] = useState<boolean>(true);
    const [loadingSessions, setLoadingSessions] = useState<boolean>(true);

    const [authors, setAuthors] = useState<User[]>([]);
    const [loadingAuthors, setLoadingAuthors] = useState<boolean>(true);

    const [titleFilter, setTitleFilter] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<SessionStatusFilter>(SessionStatusFilter.ALL);
    const [authorFilters, setAuthorFilters] = useState<string[]>([]);
    const [sortAttribute, setSortAttribute] = useState<SessionSortAttribute>(SessionSortAttribute.LAST_MODIFIED);
    const [sortOrder, setSortOrder] = useState<SessionSortOrder>(SessionSortOrder.DESCENDING);

    const { activeRepo, addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver, appIsReady } =
        useSolverInterfaceContext();

    useEffect(() => {
        const doLoadSessions = async () => {
            if (!appIsReady || !activeRepo) {
                setSessions([]);
            } else {
                const authors = await loadAuthors(activeRepo.org, activeRepo.name);
                loadSessions(activeRepo.org, activeRepo.name, authors);
            }
        };

        doLoadSessions();
    }, [appIsReady, activeRepo]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_CREATED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionId = (solverInterfaceEvent as SessionCreatedEvent).session_id;

                setSessions((prevSessions) => {
                    if (!prevSessions.map((s) => s.session_id).includes(sessionId)) {
                        getSessionBase(sessionId).then((newSession) => {
                            setSessions((prevSessions) => [newSession, ...prevSessions]);
                        });
                    }
                    return prevSessions;
                });
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, []);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_DELETED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionId = (solverInterfaceEvent as SessionDeletedEvent).session_id;

                setSessions((prevSessions) => prevSessions.filter((s) => s.session_id !== sessionId));
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [sessions]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_STATUS,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionStatusEvent = solverInterfaceEvent as SessionStatusEvent;

                updateSessionStatus(sessionStatusEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [sessions]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_CHANGED,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const sessionChangedEvent = solverInterfaceEvent as SessionStatusEvent;

                updateSession(sessionChangedEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [sessions]);

    const updateSessionStatus = (statusEvent: SessionStatusEvent) => {
        setSessions((prevSessions) => {
            return prevSessions.map((s) => {
                if (s.session_id === statusEvent.session_id) {
                    return {
                        ...s,
                        status: statusEvent.status as SessionStatus,
                    };
                } else {
                    return s;
                }
            });
        });
    };

    const updateSession = async (changedEvent: SessionChangedEvent) => {
        if (!sessions.find((s) => s.session_id === changedEvent.session_id)) return;

        const changedSession = await getSessionBase(changedEvent.session_id);

        setSessions((prevSessions) => {
            return prevSessions.map((s) => {
                if (s.session_id === changedEvent.session_id) {
                    return changedSession;
                } else {
                    return s;
                }
            });
        });
    };

    const loadSessions = async (org: string, repo: string, authors: User[] = []) => {
        setPage(0);
        setHaveMoreSessions(true);
        setLoadingSessions(true);
        setSessions(
            await getSessions(
                org,
                repo,
                titleFilter,
                authorFilters.length > 0 ? authorFilters : authors.map((u) => u.id),
                sessionStatusFilterToSessionStatuses(statusFilter as SessionStatusFilter),
                sortAttribute,
                sortOrder,
                0,
                SESSIONS_PAGE_SIZE
            )
        );
        setLoadingSessions(false);
    };

    const loadMoreSessions = async (org: string, repo: string) => {
        if (!haveMoreSessions) {
            return;
        }

        const currentPage = page;
        setPage((prevPage) => prevPage + 1);

        setLoadingSessions(true);
        const newSessions = await getSessions(
            org,
            repo,
            titleFilter,
            authorFilters,
            sessionStatusFilterToSessionStatuses(statusFilter as SessionStatusFilter),
            sortAttribute,
            sortOrder,
            currentPage + 1,
            SESSIONS_PAGE_SIZE
        );

        if (newSessions.length === 0) {
            setHaveMoreSessions(false);
        } else {
            setSessions((prevSessions) => [...prevSessions, ...newSessions]);
        }

        setLoadingSessions(false);
    };

    const loadAuthors = async (org: string, repo: string) => {
        setLoadingAuthors(true);
        const users = await getRepoUsers(org, repo);
        setAuthors(users);
        setLoadingAuthors(false);

        return users;
    };

    // TODO: expose this as a button to clear existing filters.
    // TODO: this could take args to reset to a specific state?
    const resetBrowsingFilters = async () => {
        if (
            titleFilter === "" &&
            statusFilter === SessionStatusFilter.ALL &&
            authorFilters.length === 0 &&
            sortAttribute === SessionSortAttribute.LAST_MODIFIED &&
            sortOrder === SessionSortOrder.DESCENDING
        ) {
            return;
        }

        setTitleFilter("");
        setStatusFilter(SessionStatusFilter.ALL);
        setAuthorFilters([]);
        setSortAttribute(SessionSortAttribute.LAST_MODIFIED);
        setSortOrder(SessionSortOrder.DESCENDING);

        if (activeRepo && appIsReady) {
            loadSessions(activeRepo.org, activeRepo.name);
        }
    };

    const setAuthorFiltersFn = (userIds: string[]) => {
        setAuthorFilters(userIds);
    };

    const addAuthorFilter = (user_id: string) => {
        setAuthorFilters((prevFilters) => [...prevFilters, user_id]);
    };

    const removeAuthorFilter = (user_id: string) => {
        setAuthorFilters((prevFilters) => prevFilters.filter((id) => id !== user_id));
    };

    const updateTitle = async (session_id: string, newName: string): Promise<boolean> => {
        const success = await updateSessionTitle(session_id, newName);
        if (success) {
            setSessions((prevSessions) => {
                return prevSessions.map((s) => {
                    if (s.session_id === session_id) {
                        return {
                            ...s,
                            title: newName,
                        };
                    } else {
                        return s;
                    }
                });
            });

            resetBrowsingFilters();
        } else {
            return false;
        }

        return success;
    };

    const value = {
        sessions,
        loadingSessions,
        page,
        haveMoreSessions,
        authors,
        loadingAuthors,
        titleFilter,
        setTitleFilter,
        statusFilter,
        setStatusFilter,
        authorFilters,
        setAuthorFilters: setAuthorFiltersFn,
        addAuthorFilter,
        removeAuthorFilter,
        sortAttribute,
        setSortAttribute,
        sortOrder,
        setSortOrder,
        loadSessions,
        loadMoreSessions,
        updateTitle,
        resetBrowsingFilters,
    };

    return <SessionBrowsingContext.Provider value={value}>{children}</SessionBrowsingContext.Provider>;
};

const useSessionBrowsingContext = () => {
    return React.useContext(SessionBrowsingContext);
};

export type { SessionBrowsingContextType };

export { SessionBrowsingProvider, useSessionBrowsingContext };
