"use client";
import React from "react";
import { NavigationBehavior } from "../data/Navigation";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { AuthType, vcsProviderRepoConfigUrl } from "../data/User";
import { Button, Col, Dropdown, Row, Space } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { CaretDownOutlined, LoadingOutlined, PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";
import "../index.css";
import RepoCard from "./RepoCard";
import SettingsPopover from "./SettingsPopover";
import SolverLogo from "./SolverLogo";
import { useLoadSession } from "../data/SolverSession";

const SolverHeader: React.FC = () => {
    const loadSession = useLoadSession();
    const { currentUser, loggedIn, repos, activeRepo, loadingRepos, setActiveRepo, userNotAllowListed } =
        useSolverInterfaceContext();

    const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);

    const buildHeaderActions = () => {
        return (
            <Row wrap={false} align="middle" className="header-action-bar">
                {loggedIn && buildDropdownButton()}
                {(loggedIn || userNotAllowListed) && buildSettingsButton()}
            </Row>
        );
    };

    const reposExcludingActive = () => {
        if (repos === undefined) return [];

        return repos.filter((repo) => repo !== activeRepo);
    };

    const buildMenuItems = () => {
        const menuItems: ItemType[] = reposExcludingActive().map((repo, index) => {
            return {
                label: `${repo.org} / ${repo.name}`,
                key: index,
                onClick: () => {
                    setActiveRepo(repo, NavigationBehavior.PUSH);
                    loadSession(undefined, NavigationBehavior.NONE);
                },
            };
        });

        if (currentUser?.auth_type === AuthType.GitHub) {
            if (menuItems.length > 0) {
                menuItems.push({ type: "divider" });
            }

            menuItems.push({
                icon: (
                    <div className="repo-dropdown-add-repo-label">
                        <PlusCircleOutlined />
                        Add a repo
                    </div>
                ),
                key: -1,
                onClick: () => {
                    window.open(vcsProviderRepoConfigUrl(currentUser.auth_type), "_blank")?.focus();
                },
            });
        }

        return menuItems;
    };

    const buildDropdownButton = () => {
        const menuItems = buildMenuItems();

        return (
            <Dropdown
                disabled={!loggedIn || loadingRepos}
                menu={{
                    items: menuItems,
                }}
                // TODO: Small visual issue: LAR-854
                // Scrolling to the bottom removes the top border of the dropdown
                // and scrolling to the top removes the bottom border.
                overlayClassName="repo-dropdown-overlay"
                placement="bottomLeft"
                className="repo-dropdown"
                trigger={["click"]}
            >
                <Space align="center">
                    {menuItems.length > 0 && <CaretDownOutlined />}
                    {dropdownButtonContent()}
                </Space>
            </Dropdown>
        );
    };

    const dropdownButtonContent = () => {
        if (!loggedIn) {
            return undefined;
        } else if (loadingRepos) {
            return <LoadingOutlined className="loading-repos-icon" />;
        } else if (activeRepo) {
            return <RepoCard repo={activeRepo} />;
        } else if (repos.length === 0) {
            return "No Repos Found";
        }
    };

    const buildSettingsButton = () => {
        return (
            <SettingsPopover popoverOpen={settingsOpen} onOpenChange={setSettingsOpen}>
                <Space>
                    <Button className="settings-btn" onClick={() => setSettingsOpen(!settingsOpen)}>
                        <SettingOutlined />
                    </Button>
                </Space>
            </SettingsPopover>
        );
    };

    return (
        <>
            <Row wrap={false} align="middle" className="session-header">
                <Col flex="auto">
                    <SolverLogo onClick={() => loadSession(undefined, NavigationBehavior.PUSH)} />
                </Col>
                <Col flex="none">{buildHeaderActions()}</Col>
            </Row>
        </>
    );
};

export default SolverHeader;
