"use client";
import { Input } from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import SolverMarkdown from "./SolverMarkdown";

interface DetailEditorProps {
    value: string | undefined;
    onChange?: (value: string | undefined) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    disabled?: boolean;
}

export interface FocusableRef {
    focus: () => void;
}

const DetailEditor = forwardRef<FocusableRef, DetailEditorProps>(
    ({ value, onChange, onKeyDown, placeholder, disabled = false }, ref) => {
        const textareaRef = useRef<TextAreaRef | null>(null);
        useImperativeHandle(
            ref,
            () => {
                return {
                    focus: () => {
                        setEditMode();
                    },
                };
            },
            []
        );

        const [textMode, setTextMode] = React.useState<"edit" | "preview">("preview");

        const getTextArea = () => {
            if (!textareaRef.current) return null;

            return textareaRef.current?.resizableTextArea?.textArea;
        };

        const setEditMode = () => {
            setTextMode("edit");

            const textArea = getTextArea();
            if (!textArea) return;

            // Needs to be visible to set focus.
            textArea.style.display = "block";
            textArea.focus();
        };

        return (
            <>
                <Input.TextArea
                    style={{ display: textMode === "edit" ? "block" : "none" }}
                    ref={textareaRef}
                    value={value}
                    onChange={(e) => {
                        if (onChange) onChange(e.target.value);
                    }}
                    onBlur={() => {
                        setTextMode("preview");
                    }}
                    placeholder={placeholder}
                    autoSize={{ minRows: 1, maxRows: 12 }}
                    className="nl-text-input"
                    onFocus={(e) => {
                        const textLength = e.target.value.length;
                        e.target.setSelectionRange(textLength, textLength);
                    }}
                    onKeyDown={(e) => {
                        if (onKeyDown) onKeyDown(e);
                    }}
                    disabled={disabled}
                />
                <div
                    className="nl-text-preview"
                    style={{ display: textMode === "preview" ? "block" : "none" }}
                    onClick={() => setEditMode()}
                >
                    {!value ? (
                        <div className="nl-text-preview-placeholder">{placeholder}</div>
                    ) : (
                        <SolverMarkdown text={value} />
                    )}
                </div>
            </>
        );
    }
);

DetailEditor.displayName = "DetailEditor";

export default DetailEditor;
