"use client";
import {
    ArrowLeftOutlined,
    FilterOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Divider, Input, Popover, Tooltip } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from "react-window";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSessionBrowsingContext } from "../data/SessionBrowsing";
import { SessionStub } from "../data/SolverSession";
import { useDebounce } from "../hooks/useDebounce";

import "../index.css";
import FilterList from "./FilterList";
import SessionCard from "./SessionCard";

import { Repo } from "../data/Repos";
import { SessionStatus } from "../data/SolverSession";
import { User } from "../data/User";

const propertyValueAsInt = (property: string) => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue(property));
};

enum AuthorSelectionFilter {
    ALL = "All",
    MINE = "Mine",
}

interface SessionsListItemData {
    sessions: SessionStub[];
    onSwitchSession: (session: SessionStub) => Promise<void>;
    onUpdateTitle: (session: SessionStub, newName: string) => Promise<boolean>;
    onDeleteSession: (session: SessionStub) => void;
    onCloneSession: (session: SessionStub) => void;
    notification: NotificationInstance;
    currentUser: User | undefined;
}

const SessionsListRow: React.FC<ListChildComponentProps<SessionsListItemData>> = ({ index, style, data }) => {
    const session = data.sessions[index];
    const { onSwitchSession, onUpdateTitle, onDeleteSession, onCloneSession, notification, currentUser } = data;

    return (
        <li
            className="session-list-item"
            key={session.session_id}
            onClick={(e) => {
                // Don't switch if the user is selecting text.
                if (document.getSelection()?.toString() !== "") return;

                onSwitchSession(session);
                e.preventDefault();
            }}
            style={style}
        >
            <SessionCard
                currentUser={currentUser}
                session={session}
                selected={false}
                notification={notification}
                onUpdateTitle={(newName) => onUpdateTitle(session, newName)}
                onDelete={() => onDeleteSession(session)}
                onClone={() => onCloneSession(session)}
                editable={currentUser?.id === session.user_id}
            />
        </li>
    );
};

enum ListHeaderMode {
    SEARCH,
    CREATE,
}

const SessionsList: React.FC<{
    currentUser: User | undefined;
    repo: Repo;
    activeSession: SessionStub | undefined;
    activeSessionStatus: SessionStatus;
    onNewSession: (branch: string) => Promise<void>;
    onSwitchSession: (session: SessionStub) => Promise<void>;
    onUpdateTitle: (session: SessionStub, newName: string) => Promise<boolean>;
    onDeleteSession: (session: SessionStub) => void;
    onCloneSession: (session: SessionStub) => void;
    notification: NotificationInstance;
}> = ({
    currentUser,
    repo,
    activeSession,
    activeSessionStatus,
    onNewSession,
    onSwitchSession,
    onUpdateTitle,
    onDeleteSession,
    onCloneSession,
    notification,
}) => {
    const [listHeaderMode, setListHeaderMode] = useState<ListHeaderMode>(ListHeaderMode.SEARCH);
    const [createInProgress, setCreateInProgress] = React.useState<boolean>(false);
    const [branchSearchText, setBranchSearchText] = React.useState<string>("");
    const [shouldCreateSession, setShouldCreateSession] = React.useState<boolean>(false);
    const [authorSelectionFilter, setAuthorSelectionFilter] = React.useState<AuthorSelectionFilter>(
        AuthorSelectionFilter.ALL
    );

    const {
        sessions,
        loadingSessions,
        page,
        haveMoreSessions,
        titleFilter,
        setTitleFilter,
        authors,
        setAuthorFilters,
        loadSessions,
        loadMoreSessions,
    } = useSessionBrowsingContext();

    const debouncedLoadSessions = useDebounce(() => {
        loadSessions(repo.org, repo.name);
    }, 1000);

    useEffect(() => {
        setBranchSearchText("");
        setAuthorSelectionFilter(AuthorSelectionFilter.ALL);
    }, [repo]);

    const listableSessions = useMemo(() => {
        return sessions.filter((session: SessionStub) => session.session_id !== activeSession?.session_id);
    }, [sessions, activeSession]);

    const [listHeight, setListHeight] = useState<number>(
        document.body.scrollHeight -
            propertyValueAsInt("--app-header-height") -
            propertyValueAsInt("--sessions-list-header-height")
    );

    useLayoutEffect(() => {
        let height =
            document.body.scrollHeight -
            propertyValueAsInt("--app-header-height") -
            propertyValueAsInt("--sessions-list-header-height");

        if (activeSession) {
            height -= propertyValueAsInt("--active-session-card-height");
            height -= propertyValueAsInt("--sessions-list-divider-height");
        }

        setListHeight(height);
    }, [activeSession]);

    useEffect(() => {
        if (shouldCreateSession) {
            createNewSession();
            setShouldCreateSession(false);
        }
    }, [shouldCreateSession, branchSearchText]);

    const createNewSession = async () => {
        if (createInProgress) return;
        if (!repo.defaultBranch && branchSearchText === "") return;

        const selectedBranch = branchSearchText === "" ? repo.defaultBranch : branchSearchText;
        if (!selectedBranch) return;

        const matchingBranch = repo.branches.find((branch) => branch.toLowerCase() === selectedBranch.toLowerCase());
        if (!matchingBranch) {
            notification.error({
                placement: "bottomRight",
                message: "Branch not found",
                description: `Branch ${selectedBranch} not found in ${repo.org}/${repo.name}.`,
            });
            return;
        }

        setCreateInProgress(true);
        await onNewSession(matchingBranch);
        setListHeaderMode(ListHeaderMode.SEARCH);
        setBranchSearchText("");
        setCreateInProgress(false);
    };

    const onScrollList = (scrollProps: ListOnScrollProps) => {
        if (loadingSessions) return;
        if (!haveMoreSessions) return;

        const cardMaxHeight = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue("--inactive-session-card-height")
        );

        const scrollHeight = listableSessions.length * cardMaxHeight;
        const isFullyScrolled = scrollHeight - scrollProps.scrollOffset <= listHeight;

        if (isFullyScrolled && scrollProps.scrollDirection === "forward") {
            loadMoreSessions(repo.org, repo.name);
        }
    };

    const buildNewSessionButton = () => {
        const newSessionButtonTooltipText =
            repo.branches.length > 0
                ? "Create new Session"
                : "No branches were found in this repo, please create a branch to create a Session";

        const autoCompleteTooltipText = repo.branches.length > 0 ? "Search branches" : "";

        return (
            <div className="session-list-header">
                <Button
                    onClick={() => {
                        setListHeaderMode(ListHeaderMode.SEARCH);
                    }}
                    icon={<ArrowLeftOutlined />}
                />
                <Tooltip title={autoCompleteTooltipText} placement="top" arrow={false}>
                    <AutoComplete
                        value={branchSearchText}
                        onChange={setBranchSearchText}
                        className="new-session-branch-input"
                        popupMatchSelectWidth={300}
                        placeholder={repo.defaultBranch || "No branches found"}
                        onSelect={(value) => {
                            setBranchSearchText(value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                                const matchingBranch = repo.branches.find(
                                    (branch) => branch.toLowerCase() === branchSearchText.toLowerCase()
                                );
                                if (matchingBranch) {
                                    setBranchSearchText(matchingBranch);
                                }
                                setShouldCreateSession(true);
                            }
                        }}
                        disabled={repo.branches.length === 0}
                        options={repo.branches.map((branch) => {
                            return { value: branch, label: branch };
                        })}
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Tooltip>
                <Tooltip title={newSessionButtonTooltipText} placement="right" arrow={false}>
                    <Button
                        loading={createInProgress}
                        onClick={() => {
                            createNewSession();
                        }}
                        icon={<PlusCircleOutlined />}
                    />
                </Tooltip>
            </div>
        );
    };

    const buildActiveSessionCard = () => {
        if (!activeSession) return null;

        activeSession.status = activeSessionStatus;

        return (
            <>
                <div className="active-session-card-wrapper">
                    <SessionCard
                        currentUser={currentUser}
                        session={activeSession}
                        selected={true}
                        notification={notification}
                        onUpdateTitle={(newName) => onUpdateTitle(activeSession, newName)}
                        onDelete={() => onDeleteSession(activeSession)}
                        onClone={() => onCloneSession(activeSession)}
                        editable={currentUser?.id === activeSession.user_id}
                    />
                </div>
                <Divider type={"horizontal"} className="sider-divider" />
            </>
        );
    };

    const buildList = () => {
        if (loadingSessions && page < 1) {
            return (
                <div style={{ textAlign: "center" }}>
                    <LoadingOutlined />
                </div>
            );
        } else if (sessions.length === 0) {
            return <div style={{ textAlign: "center" }}>No sessions found</div>;
        }

        const listData: SessionsListItemData = {
            sessions: listableSessions,
            onSwitchSession,
            onUpdateTitle,
            onDeleteSession,
            onCloneSession,
            notification,
            currentUser,
        };

        return (
            <FixedSizeList
                style={{
                    // Reserve space for the scrollbar even when the container is not scrollable.
                    scrollbarGutter: "stable",
                }}
                height={listHeight}
                itemCount={listableSessions.length}
                itemSize={propertyValueAsInt("--inactive-session-card-height")}
                itemData={listData}
                width="100%"
                onScroll={onScrollList}
            >
                {SessionsListRow}
            </FixedSizeList>
        );
    };

    const buildFilterList = () => {
        return <div className="session-filter-list">{buildFilterListContent()}</div>;
    };

    const buildFilterListContent = () => {
        return (
            <FilterList
                items={[
                    { key: AuthorSelectionFilter.ALL, label: "All Sessions" },
                    { key: AuthorSelectionFilter.MINE, label: "My Sessions" },
                ]}
                selectedKeys={[authorSelectionFilter]}
                onSelect={(key) => {
                    setAuthorSelectionFilter(key as AuthorSelectionFilter);

                    if (key === AuthorSelectionFilter.ALL) {
                        setAuthorFilters(authors.map((author) => author.id));
                    } else {
                        setAuthorFilters([]);
                    }

                    debouncedLoadSessions();
                }}
                buttonStyle="radio"
            />
        );
    };

    return (
        <>
            {listHeaderMode === ListHeaderMode.CREATE && buildNewSessionButton()}
            {listHeaderMode === ListHeaderMode.SEARCH && (
                <div className="session-list-header">
                    <Input
                        value={titleFilter}
                        onChange={(e) => {
                            setTitleFilter(e.target.value);

                            debouncedLoadSessions();
                        }}
                        placeholder="Search sessions"
                        addonBefore={<SearchOutlined />}
                        addonAfter={
                            <Popover
                                arrow={false}
                                trigger={"click"}
                                content={buildFilterList()}
                                placement="bottomRight"
                            >
                                <Tooltip title="Filter" arrow={false} placement="right">
                                    <Button type="text" size="small" icon={<FilterOutlined />} />
                                </Tooltip>
                            </Popover>
                        }
                        allowClear
                    />
                    <Tooltip title="Create new Session" placement="right" arrow={false}>
                        <Button
                            onClick={() => {
                                setListHeaderMode(ListHeaderMode.CREATE);
                            }}
                            icon={<PlusCircleOutlined />}
                        />
                    </Tooltip>
                </div>
            )}
            {buildActiveSessionCard()}
            {buildList()}
        </>
    );
};

export default SessionsList;
