import { theme } from "antd";

const { darkAlgorithm } = theme;

export const APP_THEME = {
    algorithm: darkAlgorithm,
    token: {
        colorPrimary: getComputedStyle(document.documentElement).getPropertyValue("--solver-blue"),
        colorPrimaryBorderHover: getComputedStyle(document.documentElement).getPropertyValue("--solver-blue-hover"),
    },
};
