import { Button } from "antd";

import "./Common.css";

export const buildCommitLabel = (author: string, date: string, message: string) => {
    return (
        <div className="commit-label">
            <div className="commit-message">{message}</div>
            <div className="commit-author-date">{`By: ${author} on ${date}`}</div>
        </div>
    );
};

export const buildGithubLinkButton = (fullRepoName: string, commit: string) => {
    return (
        <Button
            size="small"
            type="link"
            href={`https://github.com/${fullRepoName}/commit/${commit}`}
            target="_blank"
            className="commit-button"
            onClick={(e) => e.stopPropagation()}
        >
            {commit.slice(0, 7)}
        </Button>
    );
};
