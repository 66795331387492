"use client";
import React from "react";

import { ExecutionContent } from "../../data/TurnEventContent";

import Terminal from "./Terminal";

interface ExecutionProps {
    executionContent: ExecutionContent;
}

const Execution: React.FC<ExecutionProps> = ({ executionContent }) => {
    return (
        <Terminal
            command={executionContent.command}
            stdout={executionContent.stdout}
            stderr={executionContent.stderr}
            exitCode={executionContent.exit_code}
            completed={executionContent.completed}
            showLabels={true}
        />
    );
};

export default Execution;
