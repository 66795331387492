import { Span, trace, Tracer } from "@opentelemetry/api";
import { Resource } from "@opentelemetry/resources";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";

import { BatchSpanProcessor, TracerConfig, WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from "@opentelemetry/semantic-conventions";

const SERVICE_NAME = "solver-webapp";

enum TelemetryExceptionSource {
    ERROR = "error", // window.onerror
    UNHANDLED_PROMISE_REJECTION = "unhandled_promise_rejection", // window.onunhandledrejection
}

const ENG_SUBDOMAIN = "eng";
const PROD_SUBDOMAIN = "solver";

const validSubdomains = [ENG_SUBDOMAIN, PROD_SUBDOMAIN];

export const initTelemetry = (host: string) => {
    const domainParts = host.split(".");
    if (domainParts.length < 2) return;

    const domainEnv = domainParts[1];
    if (!validSubdomains.includes(domainEnv)) return;

    const telemetryUrl = `https://telemetry.${domainEnv}.laredolabs.com:443/v1/traces`;

    const collectorOptions = {
        url: telemetryUrl,
    };

    const providerConfig: TracerConfig = {
        resource: new Resource({
            [ATTR_SERVICE_NAME]: SERVICE_NAME,
            [ATTR_SERVICE_VERSION]: process.env.REACT_APP_SERVICE_VERSION || "unset",
            // TODO: Use the constant when it's available. serice.namespace is marked experimental in the spec.
            "service.namespace": domainEnv,
        }),
    };

    const provider = new WebTracerProvider(providerConfig);

    provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter(collectorOptions)));

    provider.register({
        contextManager: new ZoneContextManager(),
    });

    registerInstrumentations({
        instrumentations: [
            new DocumentLoadInstrumentation(),
            new FetchInstrumentation(),
            new XMLHttpRequestInstrumentation(),
        ],
    });

    window.addEventListener("error", (event) => {
        const error = event.error;
        if (error) {
            recordException(error, TelemetryExceptionSource.ERROR);
        }
    });

    window.addEventListener("unhandledrejection", (event) => {
        const error = event.reason;
        if (error instanceof Error) {
            recordException(error, TelemetryExceptionSource.UNHANDLED_PROMISE_REJECTION);
        }
    });
};

const recordException = (error: Error, exceptionType: TelemetryExceptionSource) => {
    const activeSpan: Span | undefined = trace.getActiveSpan();
    if (activeSpan) {
        activeSpan.recordException(error);
    } else {
        const tracer: Tracer = trace.getTracer(`${SERVICE_NAME}-${exceptionType}`);
        const newSpan: Span = tracer.startSpan(exceptionType);
        newSpan.recordException(error);
        newSpan.end();
    }
};
