"use client";
import React from "react";

import { Typography } from "antd";

import "./Terminal.css";

interface TerminalProps {
    command?: string;
    stdout?: string;
    stderr?: string;
    exitCode: number;
    completed: boolean;
    showLabels: boolean;
}

const Terminal: React.FC<TerminalProps> = ({ command, stdout, stderr, exitCode, completed, showLabels = true }) => {
    return (
        <div>
            {command && (
                <pre className="terminal">
                    $ {command}
                    <br />
                    {stdout}
                </pre>
            )}
            {stderr && (
                <div>
                    {showLabels && (
                        <Typography.Text type="danger" className="terminal-label">
                            Stderr:
                        </Typography.Text>
                    )}
                    <pre className="terminal danger">{stderr}</pre>
                </div>
            )}
            {completed && showLabels && (
                <Typography.Text type={exitCode === 0 ? "success" : "danger"} className="terminal-label">
                    Exit Code: {exitCode}
                </Typography.Text>
            )}
        </div>
    );
};

export default Terminal;
