import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Layout, notification, Radio, Tooltip } from "antd";
import classNames from "classnames";
import html2canvas from "html2canvas";
import React, { useEffect, useRef, useState } from "react";
import NaturalLanguageInput from "./NaturalLanguageInput";

import { useSolverInterfaceContext } from "../data/SolverInterface";
import { LoadingSessionState, useLoadingSessionState, useSession, useTurns } from "../data/SolverSession";
import ChangesView from "./ChangesView";
import Conversation from "./Conversation";

const { Content } = Layout;

const ActiveSession: React.FC = () => {
    const { repos } = useSolverInterfaceContext();
    const session = useSession();
    const turns = useTurns();
    const loadingSessionState = useLoadingSessionState();

    const sessionContentRef = useRef<HTMLDivElement>(null);

    const [api, contextHolder] = notification.useNotification();

    const [viewMode, setViewMode] = useState<"conversation" | "changes" | "photo">("conversation");
    // TODO: When we add navigation, we should add the scroll position to the state
    // object in history.pushState calls. This way it will be preserved in between active
    // sessions.
    const [conversationScrollPosition, setConversationScrollPosition] = useState<number>(0);
    const [changesScrollPosition, setChangesScrollPosition] = useState<number>(0);

    // Reset viewMode to "conversation" when the active session changes
    useEffect(() => {
        setViewMode("conversation");
        setConversationScrollPosition(0);
        setChangesScrollPosition(0);
    }, [session]);

    const sessionContent = () => {
        switch (viewMode) {
            case "changes":
                return (
                    <ChangesView
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, changesScrollPosition);
                        }}
                    />
                );
            case "conversation":
            case "photo":
            default:
                return (
                    <Conversation
                        ref={sessionContentRef}
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, conversationScrollPosition);
                        }}
                        onShowChangesView={() => setViewMode("changes")}
                    />
                );
        }
    };

    if (repos.length === 0) {
        return <Content className="no-active-session" />;
    }

    switch (loadingSessionState) {
        case LoadingSessionState.ERROR:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Failed to load session</span>
                </Content>
            );
        case LoadingSessionState.NOT_FOUND:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Session not found</span>
                </Content>
            );
        case LoadingSessionState.LOADING:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">
                        <LoadingOutlined style={{ fontSize: "40px" }} />
                    </span>
                </Content>
            );
    }

    const handleScreenshot = () => {
        html2canvas(document.body, { useCORS: true }).then((canvas) => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "screenshot.png";
            link.click();
        });
    };

    const layoutClass = classNames({
        "session-full-height-layout": viewMode === "photo",
        "session-conversation-layout": true,
    });

    const sessionTitleClass = classNames({
        "active-session-title": true,
        "active-session-title.no-active-session": !session,
    });

    const sessionTitle = session ? session.title : "New Session";

    return (
        <Layout className={layoutClass}>
            <div className="active-session-title-container">
                <Tooltip title={sessionTitle} placement="bottom" arrow={false}>
                    <span className={sessionTitleClass}>{sessionTitle}</span>
                </Tooltip>
                <span className="active-session-controls">
                    {viewMode === "photo" && (
                        <Button onClick={handleScreenshot} type="text" icon={<CameraOutlined />} />
                    )}
                    <Radio.Group
                        size="small"
                        onChange={(e) => {
                            setViewMode((prevViewMode) => {
                                if (prevViewMode === "conversation") {
                                    setConversationScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                } else if (prevViewMode === "changes") {
                                    setChangesScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                }

                                return e.target.value;
                            });
                        }}
                        value={viewMode}
                        optionType="button"
                        disabled={!session || turns.length === 0}
                    >
                        <Radio.Button value="conversation">Conversation</Radio.Button>
                        <Radio.Button value="changes">Changes</Radio.Button>
                        <Radio.Button value="photo">Photo</Radio.Button>
                    </Radio.Group>
                </span>
            </div>
            {turns.length > 0 && (
                <div className="session-content" ref={sessionContentRef}>
                    {sessionContent()}
                </div>
            )}
            <div className="input-container">
                <NaturalLanguageInput notification={api} />
            </div>
            {contextHolder}
        </Layout>
    );
};

export default ActiveSession;
